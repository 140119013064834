import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {LoadingButton} from "@mui/lab";
import {
    Alert,
    CircularProgress,
    Container,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import axios from "axios";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import {openMessage} from "../redux/reducers/messageReducer";
import {API_URL} from "../App";
import {useTranslation} from "i18nano";
import * as React from "react";

const ActivatePage = ({title}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ts = useTranslation();

    const [u, setU] = useState("")
    const [t, setT] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [checkToken, setCheckToken] = useState(true);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const handleChangePassword = e => {
        setPassword(e.target.value);
    }
    const handleChangeConfirmPassword = e => {
        setConfirmPassword(e.target.value);
    }

    const [errors, setErrors] = useState([]);

    const submitHandler = () => {
        if (password !== confirmPassword) {
            setErrors(['Password mismatch'])
            //dispatch(openMessage(ts("Password mismatch"), 'error'))
        } else {

            setLoading(true)
            axios.post(`${API_URL}/user/${u}/password`, {
                password: password,
                confirmPassword: confirmPassword,
                token: t
            })
                .then(response => {
                    if (response.data.success === true) {
                        dispatch(openMessage("Done!", 'success'))
                        setLoading(false)
                        navigate("/login");
                    } else {
                        setErrors([response.data.errors[0]])
                        //dispatch(openMessage(response.data.errors[0], 'error'))
                        setLoading(false)
                    }
                })
                .catch(() => {
                    setErrors(["Something went wrong"]);
                    //dispatch(openMessage(ts("Error"), 'error'))
                    setLoading(false)
                })
        }
    }

    const {pathname, search} = useLocation();

    useEffect(() => {
        const urlParams = search.split("&");

        if (urlParams.length >= 2) {
            setU(urlParams[0].replace("?u=", ""));
            setT(urlParams[1].replace("t=", ""));
        } else {
            dispatch(openMessage(ts("Not valid link"), 'warning'))
            setError(ts("Not valid link"));
            //navigate("/login")
        }
        if (pathname == "/activate") {
            axios.get(`${API_URL}/user/${urlParams[0].replace("?u=", "")}/activated`)
                .then(response => {
                    if (response.data.data === true) {
                        navigate("/login")
                    } else {
                        setCheckToken(false);
                    }
                })
                .catch(() => {
                    //dispatch(openMessage(ts("Error"), 'error'));
                    setErrors(["Something went wrong"])
                    setLoading(false);
                    setCheckToken(false);
                })
        } else {
            setLoading(false);
            setCheckToken(false);
        }

    }, [])

    return (
        <Container>
            {checkToken ?
                <CircularProgress sx={{position: "absolute", left: "calc(50% - 20px)", top: "30%"}}/>
                : <Stack
                    spacing={2}
                    sx={{
                        maxWidth: 400,
                        /*position: "absolute",
                        left: (window.innerWidth / 2) - 200,*/
                        margin: "auto",
                        marginTop: 5
                    }}
                    justifyContent={'center'}
                >
                    <Typography variant="h4" align={'center'}>
                        {title}
                    </Typography>
                    <Typography align={"justify"} variant={"body2"}>
                        {ts("Choose a password that you will easily remember")}
                    </Typography>

                    {u !== "" || t !== "" ?
                        (<>
                            <TextField
                                disabled={false}
                                fullWidth
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handleChangePassword}
                                onKeyPress={e => {
                                    if (e.code === "Enter") {
                                        if (password === "" || confirmPassword === "") return;
                                        submitHandler();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"
                                                        onClick={() => setShowPassword(prev => !prev)}
                                                        onMouseDown={e => e.preventDefault()}
                                        >
                                            <IconButton
                                                onMouseDown={e => e.preventDefault()}
                                                edge="end">
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                                }
                                aria-describedby="outlined-email-helper-text"
                                label={ts("New password")}
                                variant="outlined"
                            />
                            <TextField
                                disabled={false}
                                fullWidth
                                id="confirmPassword"
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={handleChangeConfirmPassword}
                                onKeyPress={e => {
                                    if (e.code === "Enter") {
                                        if (password === "" || confirmPassword === "") return;
                                        submitHandler();
                                    }
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"
                                                        onClick={() => setShowPassword(prev => !prev)}
                                                        onMouseDown={e => e.preventDefault()}
                                        >
                                            <IconButton
                                                onMouseDown={e => e.preventDefault()}
                                                edge="end">
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                                }
                                aria-describedby="outlined-email-helper-text"
                                label={ts("Confirm new password")}
                                variant="outlined"
                            />

                            {errors?.map(e => <Alert severity="error">{e}</Alert>)}
                            <LoadingButton disabled={password === "" || confirmPassword === ""} loadingPosition="start"
                                           startIcon={<></>} loading={loading} size={"large"} variant="contained"
                                           onClick={submitHandler}>
                                {pathname === "/restore" ?
                                    loading ? `${ts("Resetting")}...` : ts("Reset password") :
                                    loading ? `${ts("Activating")}...` : ts("Activate")}
                            </LoadingButton>
                        </>)
                        : <Typography variant="h5" align={'center'}>
                            {error}
                        </Typography>
                    }

                </Stack>
            }
        </Container>
    )
}

export default ActivatePage;