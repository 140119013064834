import EmailIcon from '@mui/icons-material/Email';
import {LoadingButton} from "@mui/lab";
import {Alert, Container, IconButton, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import axios from "axios";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {openMessage} from "../redux/reducers/messageReducer";
import {API_URL} from "../App";
import {useTranslation} from "i18nano";
import * as React from "react";

const ResetPassword = () => {
    const dispatch = useDispatch();
    const t = useTranslation();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false)

    const handleChangeEmail = e => {
        setEmail(e.target.value);
    }

    const [notifications, setNotifications] = useState([]);

    const submitHandler = () => {
        setLoading(true)
        axios.post(`${API_URL}/user/restore`, {email: email})
            .then(response => {
                if (response.data.success === true) {
                    setNotifications([{text : t("We have sent you an e-mail containing your password reset link"), severity: "success"}])
                    //dispatch(openMessage(t("We have sent you an e-mail containing your password reset link"), 'success'))
                    setLoading(false)
                } else {
                    //dispatch(openMessage(response.data.errors[0], 'error'))
                    setNotifications([{text: response.data.errors[0], severity: "error"}])
                    setLoading(false)
                }
            })
            .catch(error => {
                setNotifications([{text: "Something went wrong", severity: "error"}])
                //dispatch(openMessage(t("Error"), 'error'))
                setLoading(false)
            })
    }

    return (
        <Container>
            <Stack
                spacing={2}
                sx={{
                    maxWidth: 400,
                    /*position: "absolute",
                    left: (window.innerWidth / 2) - 200,*/
                    margin: "auto",
                    marginTop: 5
                }}
                justifyContent={'center'}
            >
                <Typography variant="h4" align={'center'}>
                    {t("Reset password Title")}
                </Typography>

                <TextField
                    disabled={false}
                    fullWidth
                    id="email"
                    value={email}
                    onChange={handleChangeEmail}
                    inputProps={{inputMode: "email"}}
                    onKeyPress={e => {
                        if (e.code === "Enter") {
                            if (email === "") return;
                            submitHandler();
                        }
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onMouseDown={e => e.preventDefault()}
                                    disabled={true}
                                    edge="end"
                                    className={"changeColorAccountCircle"}
                                >
                                    <EmailIcon edge="end"/>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }
                    }
                    aria-describedby="outlined-email-helper-text"
                    label={t("Email")}
                    variant="outlined"
                />
                {notifications?.map(e => <Alert severity={e.severity}>{e.text}</Alert>)}
                <LoadingButton disabled={email === ""} loading={loading}
                               loadingPosition="start" startIcon={<></>} size={"large"} variant="contained"
                               onClick={submitHandler}>{t("Confirm reset")}
                </LoadingButton>


                {/*<NavLink to="/" style={{textAlign: "center"}} >Go to authentication page</NavLink>*/}
            </Stack>

        </Container>
    )
}

export default ResetPassword;