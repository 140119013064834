import {Button, FormControl, FormLabel, Typography} from "@mui/material";
import React from "react"
import {FormHelperText, IconButton, InputAdornment, TextField} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import StatusComponent from "./StatusComponent";
import {useTranslation} from "i18nano";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const ChildComponent = React.memo(({item, value, onChange, onClickRemove, onClickAdd, onFocus}) => {
    const t = useTranslation();
    return (
        <FormControl disabled={!item.editable} required={item.required} error={value.error}>
            <FormLabel>{item.displayText}</FormLabel>
            {

                value.array.sort((a, b) => a.order - b.order).map((val, index) => {
                    return (
                        <React.Fragment key={`${val.id}${index}${val.order}`}>
                            <TextFieldMemoChildComponent
                                style={index !== 0 ? {marginTop: 5} : {}}
                                disabled={!item.editable}
                                required={item.required} value={val.value}
                                onChange={e => onChange(e, item, val.id, 0, null, val.order)}
                                onClick={() => onClickRemove(item.id, val.id)}
                                errorText={val.errorText}
                                onFocus={onFocus}
                                status={val.status}
                            />
                        </React.Fragment>
                    )
                })
            }
            <Button disabled={!item.editable} sx={{width: {xs: "100%", sm: 100}, marginTop: "5px"}}
                    onClick={() => onClickAdd(item.id)} fullWidth={false} variant="contained">{t("Add")}</Button>
        </FormControl>
    )
})

export default ChildComponent;

const TextFieldMemoChildComponent = React.memo(({
                                                    status = "none",
                                                    value,
                                                    onChange,
                                                    onClick,
                                                    disabled,
                                                    required,
                                                    type = "text",
                                                    errorText = "",
                                                    onFocus,
                                                    style = {}
                                                }) => {

    const t = useTranslation();
    return (
        <>
            <TextField disabled={disabled} required={required} value={value} style={style}
                       onChange={onChange}
                       onFocus={onFocus}
                       type={type}
                       onDrop={preventDefaultOnDropFiles}
                       InputProps={{
                           endAdornment: <InputAdornment position="end">
                               <StatusComponent status={status} elseComponent={
                                   <PopupState variant="popover" popupId="demo-popup-popover">
                                       {(popupState) => (
                                           <div>
                                               <IconButton
                                                   aria-label="delete text field"
                                                   onClick={onClick}
                                                   onMouseDown={e => e.preventDefault()}
                                                   edge="end"
                                                   disabled={disabled}
                                                   tabIndex={-1}
                                                   {...bindTrigger(popupState)}
                                               >
                                                   <DeleteIcon/>
                                               </IconButton>
                                               <Popover
                                                   {...bindPopover(popupState)}
                                                   anchorOrigin={{
                                                       vertical: 'bottom',
                                                       horizontal: 'center',
                                                   }}
                                                   transformOrigin={{
                                                       vertical: 'top',
                                                       horizontal: 'center',
                                                   }}
                                               >
                                                   <Typography
                                                       sx={{p: 2}}>{t("Do you really want to delete this party")}</Typography>
                                                   <div style={{
                                                       display: "flex",
                                                       justifyContent: "flex-end",
                                                       paddingBottom: "5px",
                                                       marginRight: "5px"
                                                   }}>
                                                       <Button variant="contained" onClick={() => {
                                                           onClick();
                                                           popupState.close();
                                                       }}>
                                                           {t("Yes")}
                                                       </Button>
                                                       <Button variant="contained" color={"error"}
                                                               onClick={popupState.close} sx={{marginLeft: "5px"}}>
                                                           {t("No")}
                                                       </Button>
                                                   </div>

                                               </Popover>
                                           </div>
                                       )}
                                   </PopupState>
                               }/>
                           </InputAdornment>,
                           inputMode: type === "number" ? 'numeric' : "text",
                           pattern: type === "number" ? '[0-9]*' : ""
                       }}

                       error={errorText !== ""}
            />
            {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
        </>
    )
}, (prev, next) =>
    prev.value === next.value &&
    prev.errorText === next.errorText &&
    prev.status === next.status)