import {Fab, Popover} from "@mui/material";
import React from "react";
import SearchIcon from '@mui/icons-material/Search';

const SearchFloatButton = ({children, sx}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const open = Boolean(anchorEl);

    return (
        <>
            <Fab
                color="secondary"
                sx={sx}
                onClick={handleClick}>
                <SearchIcon/>
            </Fab>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                sx={{transform : "translateY(-10px)"}}
            >
                {children}
            </Popover>
        </>
    );
};

export default SearchFloatButton;