const defaultState = {
    isAuth: -1, // not check user
    loading: true,
    loadingButton: true,
    errorData: {},
    authenticatorIsConnected: false,
    phoneIsConnected: false
}

const AUTH_SUCCESS = "AUTH_SUCCESS";
const LOGOUT = "LOGOUT";
const CHECK_AUTH_SUCCESS = "CHECK_AUTH_SUCCESS";
const CHECK_AUTH_ERROR = "CHECK_AUTH_ERROR";
const SET_LOADING_BUTTON = "SET_LOADING_BUTTON";
const SET_AUTHENTICATOR_IS_CONNECTED = "SET_AUTHENTICATOR_IS_CONNECTED";
const SET_PHONE_IS_CONNECTED = "SET_PHONE_IS_CONNECTED";

export const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case AUTH_SUCCESS: {
            localStorage.setItem("token", action.payload.accessToken);
            localStorage.setItem("refreshToken", action.payload.refreshToken)
            return {...state, isAuth: true}
        }

        case LOGOUT: {
            localStorage.removeItem("token");
            localStorage.removeItem("lastApplicant");
            return {...state, isAuth: false, errorData: {}}
        }

        case CHECK_AUTH_SUCCESS: {
            return {...state, isAuth: true}
        }
        case CHECK_AUTH_ERROR: {
            localStorage.removeItem("token");
            localStorage.removeItem("lastApplicant");
            let authenticatorIsConnected = false;
            let phoneIsConnected = false;
            if (action.payload && !action.payload.errors.includes("AUTHENTICATOR_IS_NOT_CONNECTED")) {
                authenticatorIsConnected = true;
            }
            if (action.payload && !action.payload.errors.includes("PHONE_IS_NOT_CONNECTED")) {
                phoneIsConnected = true;
            }
            return {
                ...state, isAuth: false, loading: false, loadingButton: false,
                errorData: action.payload, phoneIsConnected, authenticatorIsConnected
            }
        }
        case SET_LOADING_BUTTON: {
            return {...state, loadingButton: action.payload}
        }
        case SET_AUTHENTICATOR_IS_CONNECTED: {
            return {...state, authenticatorIsConnected: action.payload}
        }
        case SET_PHONE_IS_CONNECTED: {
            return {...state, phoneIsConnected: action.payload}
        }
        default:
            return state;
    }
}

export const authSuccess = (payload) => ({type: AUTH_SUCCESS, payload});
export const logout = () => ({type: LOGOUT});
export const checkAuthSuccess = () => ({type: CHECK_AUTH_SUCCESS});
export const checkAuthError = (payload) => ({type: CHECK_AUTH_ERROR, payload});
export const setLoadingButton = (payload) => ({type: SET_LOADING_BUTTON, payload});
export const setAuthenticatorIsConnected = payload => ({type: SET_AUTHENTICATOR_IS_CONNECTED, payload})
export const setPhoneIsConnected = payload => ({type: SET_PHONE_IS_CONNECTED, payload})