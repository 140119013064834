import {createStore, combineReducers, applyMiddleware} from "redux"
import thunk from "redux-thunk"
import {authReducer} from "./reducers/authReducer"
import {applicantsReducer} from "./reducers/applicantsReducer"
import {partyReducer} from "./reducers/partyReducer"
import {messageReducer} from "./reducers/messageReducer"
import {filesReducer} from "./reducers/filesReducer"
import {updateReducer} from "./reducers/updateReducer";

const rootReducer = combineReducers({
    authReducer,
    applicantsReducer,
    partyReducer,
    messageReducer,
    filesReducer,
    updateReducer
})

export const store = createStore(rootReducer, applyMiddleware(thunk));