import {FormControl, FormHelperText, FormLabel, InputAdornment, TextField} from "@mui/material";
import React from "react";
import StatusComponent from "./StatusComponent";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const TextFieldComponent = React.memo(({
                                           required,
                                           editable,
                                           displayText,
                                           value,
                                           onChange,
                                           errorText = "",
                                           onFocus = null,
                                           status = "none"
                                       }) => {
        return (
            <>
                <FormControl disabled={!editable} required={required}>
                    <FormLabel error={errorText !== ""}>{displayText}</FormLabel>
                    <TextField disabled={!editable}
                               required={required}
                               value={value}
                               onChange={onChange}
                               error={errorText !== ""}
                               onFocus={onFocus}
                               onDrop={preventDefaultOnDropFiles}
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">
                                       <StatusComponent status={status}/>
                                   </InputAdornment>
                               }}
                    />

                    {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
                </FormControl>
            </>
        )
    }, (prev, next) =>
        prev.value === next.value &&
        prev.errorText === next.errorText &&
        prev.status === next.status &&
        prev.displayText === next.displayText
)

export default TextFieldComponent;