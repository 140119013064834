import {FormControl, FormHelperText, FormLabel, InputAdornment, MenuItem, TextField} from "@mui/material";
import React from "react"
import StatusComponent from "./StatusComponent";
import {useTranslation} from "i18nano";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const DropdownComponent = React.memo(({
                                          item,
                                          value,
                                          onChange,
                                          onChangeText,
                                          errorText = "",
                                          onFocus,
                                          status = "none"
                                      }) => {
    const t = useTranslation();
    return (
        <FormControl disabled={!item.editable} required={item.required}>
            <FormLabel error={errorText !== ""} id={item.id}>{item.displayText}</FormLabel>
            <TextField error={errorText !== ""}
                       aria-labelledby="demo-radio-buttons-group-label"
                       name="dropdown-select"
                       value={

                           value.toString().indexOf("o:") !== -1 || value === "Other" ? "Other" : value

                       }
                       disabled={!item.editable}
                       onChange={onChange}
                       onFocus={onFocus}
                       className={"paddingRightForDropdown"}
                       select
                       InputProps={{
                           endAdornment: <InputAdornment position="end" sx={{
                               position: "absolute",
                               right: "30px"
                           }}>
                               <StatusComponent status={status}/>
                           </InputAdornment>,
                       }}
            >
                {

                    item.choices.map(choice => {
                        return (
                            <MenuItem key={choice.id} value={choice.id}>{choice.text}</MenuItem>
                        )
                    })
                }
                {item.other === true ? <MenuItem value={"Other"}>{t("Other")}</MenuItem> : null}
            </TextField>
            {
                value === "Other" || value.toString().indexOf("o:") !== -1
                    ?
                    <TextField onFocus={onFocus} error={errorText !== ""} sx={{marginTop: 1}} value={value.substring(2)}
                               onChange={onChangeText} label={t("Other")} variant="outlined"
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">
                                       <StatusComponent status={status}/>
                                   </InputAdornment>,
                               }}
                               onDrop={preventDefaultOnDropFiles}
                    />
                    : null
            }
            {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
        </FormControl>
    )
}, (prev, next) =>
    prev.value === next.value &&
    prev.errorText === next.errorText &&
    prev.status === next.status &&
    prev.item.displayText === next.item.displayText)

export default DropdownComponent;