import {Stack, TextField, Typography} from "@mui/material";
import {useState} from "react";
import {LoadingButton} from "@mui/lab";

const EnterCodeAuthenticatorComponent = ({onClick, loading, title, description, buttonText = "Next"}) => {
    const [code, setCode] = useState("");
    return (
        <Stack
            spacing={2}
            justifyContent={'center'}
        >
            <Typography variant="h4" align={'center'}>
                {title}
            </Typography>
            <Typography variant="body1" gutterBottom align={"center"}>
                {description}
            </Typography>

            <TextField
                fullWidth
                value={code}
                onChange={e => setCode(e.target.value)}
                aria-describedby="outlined-email-helper-text"
                label={"Code"}
                variant="outlined"
                onKeyPress={e => {
                    if (e.code === "Enter") {
                        console.log("enter")
                        if (code !== "") {
                            onClick(code);
                        }
                    }
                }}
            />
            <LoadingButton variant="contained" disabled={code === ""} onClick={() => onClick(code)}
                           loading={loading} size={"large"} loadingPosition="start" startIcon={<></>}
            >
                {buttonText}
            </LoadingButton>
        </Stack>
    )

}

export default EnterCodeAuthenticatorComponent