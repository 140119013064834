const defaultState = {
    open: false,
    message: "",
    severity: "success",

}

const OPEN_MESSAGE = "OPEN_MESSAGE";
const CLOSE_MESSAGE = "CLOSE_MESSAGE"

export const messageReducer = (state = defaultState, action) => {
    switch (action.type) {
        case OPEN_MESSAGE: {

            return {...state, open: true, message: action.payload.message, severity: action.payload.severity}
        }
        case CLOSE_MESSAGE:
            return {
                ...state,
                open: false
            }

        default:
            return state;
    }
}

export const closeMessage = () => ({type: CLOSE_MESSAGE})
export const openMessage = (message, severity) => ({type: OPEN_MESSAGE, payload: {message, severity}})