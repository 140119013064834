import {FormControl, FormHelperText, FormLabel, InputAdornment, TextField} from "@mui/material";
import React from "react"
import StatusComponent from "./StatusComponent";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const NumberComponent = React.memo(({
                                        item,
                                        value,
                                        onChange,
                                        errorText = "",
                                        onFocus,
                                        status = ""
                                    }) => {
    return (
        <FormControl disabled={!item.editable} required={item.required}>
            <FormLabel error={errorText !== ""}>{item.displayText}</FormLabel>
            <TextField disabled={!item.editable}
                //type={"number"}
                       required={item.required}
                       value={value}
                       onChange={onChange}
                       inputProps={{inputMode: 'numeric'}}
                       error={errorText !== ""}
                       onFocus={onFocus}
                       onDrop={preventDefaultOnDropFiles}
                       InputProps={{
                           endAdornment: <InputAdornment position="end">
                               <StatusComponent status={status}/>
                           </InputAdornment>,
                       }}
            />
            {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
        </FormControl>
    )
}, (prev, next) =>
    prev.value === next.value &&
    prev.errorText === next.errorText &&
    prev.status === next.status &&
    prev.item.displayText === next.item.displayText)

export default NumberComponent;