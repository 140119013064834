import {checkAuthError} from "../reducers/authReducer";
import {setNewFile} from "../reducers/filesReducer";
import {setLastTimestamp, setReload} from "../reducers/partyReducer";
import {returnBackData, setUpdateStatus} from "../reducers/updateReducer";
import {openMessage} from "../reducers/messageReducer";
import {changeCurrentApplicant} from "../reducers/applicantsReducer";
import {axiosInstanceWithToken} from "../../instances/axiosInstance";

export const answerUpdate = (data, isFile = false) => {
    return dispatch => {
        axiosInstanceWithToken.post(`answer`,
            data,
            {
                headers: {
                    "Content-type": "application/json"
                }
            })
            .then(response => {
                if (response.data.errors && response.data.errors.length > 0) {
                    if (data.oldText) {
                        dispatch(returnBackData(data))
                        dispatch(openMessage("The request failed", 'error'))
                    }
                }

                if (response.data.data && response.data.data.reload === true) {
                    dispatch(setReload(true));
                }
                dispatch(setUpdateStatus({...response.data.data, status: "updated"}))

                if (response.data.success) {
                    dispatch(setLastTimestamp(data.timestamp))

                    if (isFile) {
                        dispatch(setNewFile(response.data.data))
                    }
                }
            })
            .catch(error => {
                try {
                    dispatch(setUpdateStatus({...data, status: "error"}))
                     if (error.response.status === 403) {
                        //dispatch(openMessage("You don't have access to updated this party", 'error'))
                        dispatch(changeCurrentApplicant(-1));
                        dispatch(checkAuthError());
                    } else {
                        dispatch(openMessage("The request failed", 'error'))
                    }
                } catch (e) {
                    dispatch(setUpdateStatus({...data, status: "error"}))
                    //dispatch(openMessage("Network error", 'error'))
                }
            })
    }
}

export const modifiedParty = (partyId) => {
    return (dispatch, getState) => {
        axiosInstanceWithToken(`party/${partyId}/modified-at`)
            .then(response => {
                const lastTimestamp = getState().partyReducer.lastTimestamp;

                if (response.data.data > lastTimestamp && lastTimestamp !== 0) {
                    dispatch(setReload(true))
                }
                dispatch(setLastTimestamp(response.data.data))
            })
            .catch(error => {
                try {
                    if (error?.response?.status === 403) {
                        //dispatch(openMessage("You don't have access to updated this party", 'error'))
                        dispatch(changeCurrentApplicant(-1));
                        dispatch(checkAuthError());
                    } else {
                        dispatch(openMessage("The request failed", 'error'))
                    }
                } catch (e) {
                    //dispatch(openMessage("Network error", 'error'))
                }
            })
    }
}