// Setting up Axios in a React.js project
import axios from 'axios';
import {authSuccess, checkAuthError, logout} from "../redux/reducers/authReducer";
import {changeCurrentApplicant} from "../redux/reducers/applicantsReducer";

import {store} from "../redux/index"

export const API_URL = window.location.hostname === "cflow.carolynleepllc.com"
    ? "https://api.cflow.carolynleepllc.com"
    : "https://dev.api.cflow.carolynleepllc.com";

const axiosInstance =  axios.create({
    baseURL: API_URL,
});

const axiosInstanceWithToken = axios.create({
    baseURL: API_URL,
});
axiosInstanceWithToken.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (!token) {
            store.dispatch(checkAuthError());
            store.dispatch(changeCurrentApplicant(-1));
            return;
        }
        config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
const refreshAndRetryQueue = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

axiosInstanceWithToken.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                try {
                    const response = await axiosInstance.post("/token/refresh",{
                        accessToken: localStorage.getItem("token"),
                        refreshToken: localStorage.getItem("refreshToken"),
                    });
                    if (response.data.success) {
                        store.dispatch(authSuccess(response.data.data));
                    }
                    else{
                        store.dispatch(checkAuthError(response.data))
                        store.dispatch(changeCurrentApplicant(-1));
                        return;
                    }
                    // Refresh the access token
                    const newAccessToken = response.data.data.accessToken;

                    // Update the request headers with the new access token
                    error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;

                    // Retry all requests in the queue with the new token
                    refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                        axiosInstanceWithToken
                            .request(config)
                            .then((response) => resolve(response))
                            .catch((err) => reject(err));
                    });

                    // Clear the queue
                    refreshAndRetryQueue.length = 0;

                    // Retry the original request
                    return axiosInstanceWithToken(originalRequest);
                } catch (refreshError) {
                    // Handle token refresh error
                    // You can clear all storage and redirect the user to the login page
                    throw refreshError;
                } finally {
                    isRefreshing = false;
                }
            }

            // Add the original request to the queue
            return new Promise((resolve, reject) => {
                refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
            });
        }

        // Return a Promise rejection if the status code is not 401
        return Promise.reject(error);
    }
);
export {axiosInstanceWithToken, axiosInstance};