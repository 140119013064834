import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux"
import {TranslationProvider} from 'i18nano';
import {store} from "./redux/index"
import {BrowserRouter} from "react-router-dom";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import Loader from "./components/Loader";

const theme = createTheme({
    palette: {
        primary: {
            main: '#036aaa',
        }
    },
});

const getLanguages = async () => {
    return await fetch(`${process.env.PUBLIC_URL}/locales/languages.json`)
        .then((response) => {
            return response.json();
        });
}
const translations = {};
let enLanguage;
const loadLanguage = async (path) => {
    return fetch(path)
        .then((response) => {
            return response.json();
        })
        .then(async data => {
            if(!enLanguage){
                let response = await fetch(`${process.env.PUBLIC_URL}/locales/en.json`);
                let json = await response.json();
                enLanguage = json;
            }
            for (let property in enLanguage) {
                if(!data.hasOwnProperty(property)){
                    data[property] = enLanguage[property]
                }
            }
            return data;
        })
        .catch(async (error) =>{
            console.log(error)
            if(!enLanguage){
                let response = await fetch(`${process.env.PUBLIC_URL}/locales/en.json`);
                let json = await response.json();
                enLanguage = json;
            }
            alert("Selected language is not supported at the moment. The web version will be switched to English");
            return enLanguage;
        });
}
let defaultLanguage = localStorage.getItem("lang");

getLanguages().then(async languages => {
    languages.forEach(item => {
        const path = `${process.env.PUBLIC_URL}/locales/${item.code}.json`;
        translations[item.code] = () => loadLanguage(path);
    });
    if (!defaultLanguage) {
        defaultLanguage = "en";
    } else {
        if (!translations.hasOwnProperty(defaultLanguage)) {
            defaultLanguage = "en";
        }
    }

    try {
        ReactDOM.render(
            <BrowserRouter>
                <Provider store={store}>
                    <DndProvider backend={HTML5Backend}>
                        <ThemeProvider theme={theme}>
                            <TranslationProvider
                                translations={translations}
                                language={defaultLanguage}
                                transition={true}>
                                <Suspense fallback={
                                    <div style={{position: "absolute", left:"50%", top:"20%", transform:"translateX(-50%)",
                                        display:"flex", flexDirection:"column", alignItems:"center"}}>
                                        <Loader />
                                        <span>Loading language...</span>
                                    </div>}>
                                    <App />
                                </Suspense>
                            </TranslationProvider>
                        </ThemeProvider>
                    </DndProvider>
                </Provider>
            </BrowserRouter>,
            document.getElementById('root')
        );
    } catch (e) {
        alert(e);
    }

    reportWebVitals();
});