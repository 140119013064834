import {FormHelperText, IconButton, InputAdornment, TextField} from "@mui/material";
import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import StatusComponent from "./StatusComponent";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const TextFieldMemo = React.memo(({
                                      value,
                                      onChange,
                                      onClick,
                                      disabled,
                                      required,
                                      type = "text",
                                      errorText = "",
                                      onFocus,
                                      style = {},
                                      status = "none"
                                  }) => {
    return (
        <>
            <TextField disabled={disabled} required={required} value={value} style={style}
                       onChange={onChange}
                       onFocus={onFocus}
                       InputProps={{
                           endAdornment: (
                               <InputAdornment position="end">
                                   <StatusComponent status={status}
                                                    elseComponent={<IconButton
                                                        tabIndex={-1}
                                                        aria-label="delete text field"
                                                        onClick={onClick}
                                                        onMouseDown={e => e.preventDefault()}
                                                        edge="end"
                                                        disabled={disabled}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>}/>
                               </InputAdornment>
                           )
                       }}
                       inputProps={{inputMode: type === "number" ? 'numeric' : "text"}}


                       error={errorText !== ""}
                       onDrop={preventDefaultOnDropFiles}
            />
            {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
        </>
    )
}, (prev, next) =>
    prev.value === next.value &&
    prev.errorText === next.errorText &&
    prev.status === next.status)


export default TextFieldMemo;