import {v4} from "uuid";

const generateState = (party) => {
    let newValue = []
    let newValuesViews = [];

    party.presets.forEach(preset => {
        preset.items.forEach(item => {
            if (item.type === 0) {
                if (item.answers.length !== 0) {
                    if (item.allowMultiple || item.questionType === 8) {
                        newValue[item.id] = {
                            array: [],
                            required: item.required,
                            presetId: preset.id
                        }
                        item.answers.sort((a, b) => a.order - b.order).forEach(a => {
                                newValue[item.id].array = [...newValue[item.id].array, {
                                    value: a.text,
                                    id: a.id,
                                    required: item.required,
                                    errorText: "",
                                    order: a.order,
                                    presetId: preset.id
                                }]
                            }
                        )
                    } else if (item.questionType === 6) {
                        if (item.answers[0].attachments.length === 0) {
                            newValue[item.id] = {
                                array:
                                    [{
                                        value: [],
                                        attachmentId: [],
                                        id: item.answers[0].id,
                                        required: item.required,
                                        errorText: "",
                                        presetId: preset.id
                                    }],
                                isFileType: true,
                                required: item.required,
                                presetId: preset.id
                            }
                        } else {
                            newValue[item.id] = {
                                array: [],
                                isFileType: true,
                                required: item.required,
                                presetId: preset.id
                            };
                            item.answers[0].attachments.forEach((attachment, index) => {
                                newValue[item.id].array.push({
                                    value: attachment.displayName,
                                    attachmentId: attachment.id,
                                    id: item.answers[0].id,
                                    required: item.required,
                                    errorText: "",
                                    index: index,
                                    presetId: preset.id
                                });
                            })
                        }
                    } else {
                        newValue[item.id] = {
                            value: item.answers[0].text,
                            id: item.answers[0].id,
                            required: item.required,
                            errorText: "",
                            status: "none",
                            presetId: preset.id
                        }
                    }

                } else {
                    if (item.allowMultiple || item.questionType === 8) {
                        newValue[item.id] = {
                            array: [{value: "", id: v4(), required: item.required, errorText: "", order: 0}],
                            required: item.required,
                            presetId: preset.id
                        }
                    } else if (item.questionType === 6) {
                        newValue[item.id] = {
                            array: [],
                            isFileType: true,
                            required: item.required,
                            presetId: preset.id
                        }
                    } else {
                        newValue[item.id] = {value: "", id: v4(), required: item.required, errorText: "",
                            presetId: preset.id}
                    }
                }
            } else {
                newValuesViews[item.id] = {}
                item.views.forEach(view => {
                    newValuesViews[item.id][view.order] = {};
                    view.questions.forEach(quest => {
                        if (quest.answers.length !== 0) {
                            if (quest.allowMultiple || quest.questionType === 8) {
                                newValuesViews[item.id][view.order][quest.id] = {
                                    array: [],
                                    required: quest.required,
                                    presetId: preset.id
                                };
                                quest.answers.sort((a, b) => a.order - b.order).forEach(a => {
                                    newValuesViews[item.id][view.order][quest.id].array
                                        .push({
                                            value: a.text,
                                            id: a.id,
                                            required: quest.required,
                                            errorText: "",
                                            order: a.order,
                                            presetId: preset.id
                                        });
                                })

                            } else if (quest.questionType === 6) {
                                if (quest.answers[0].attachments.length === 0) {
                                    newValuesViews[item.id][view.order][quest.id] = {
                                        array: [{
                                            value: [],
                                            attachmentId: [],
                                            id: quest.answers[0].id,
                                            required: quest.required,
                                            errorText: "",
                                            presetId: preset.id
                                        }],
                                        isFileType: true,
                                        required: quest.required,
                                        presetId: preset.id
                                    }
                                } else {
                                    newValuesViews[item.id][view.order][quest.id] = {
                                        required: quest.required,
                                        isFileType: true,
                                        array: [],
                                        presetId: preset.id
                                    };
                                    quest.answers[0].attachments.forEach((attachment, index) => {
                                        newValuesViews[item.id][view.order][quest.id].array.push({
                                            value: attachment.displayName,
                                            attachmentId: attachment.id,
                                            id: quest.answers[0].id,
                                            required: quest.required,
                                            errorText: "",
                                            index: index,
                                            presetId: preset.id
                                        })
                                    })
                                }
                            } else {
                                newValuesViews[item.id][view.order][quest.id] =
                                    {
                                        value: quest.answers[0].text,
                                        id: quest.answers[0].id,
                                        required: quest.required,
                                        errorText: "",
                                        presetId: preset.id
                                    }
                            }

                        } else {
                            if (quest.allowMultiple) {
                                newValuesViews[item.id][view.order][quest.id] = {
                                    array: [{value: "", id: v4(), required: quest.required, errorText: "", order: 0}],
                                    required: quest.required,
                                    presetId: preset.id
                                };
                            } else if (quest.questionType === 6) {
                                newValuesViews[item.id][view.order][quest.id] = {
                                    array: [],
                                    isFileType: true,
                                    required: quest.required,
                                    presetId: preset.id
                                };
                            } else {
                                newValuesViews[item.id][view.order][quest.id] = {
                                    value: "",
                                    id: v4(),
                                    required: quest.required,
                                    errorText: "",
                                    presetId: preset.id
                                };
                            }
                        }
                    })
                })
            }
        });
    })

    console.log(newValue, newValuesViews);

    return {newValue, newValuesViews};
}

export default generateState;