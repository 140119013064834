import {Button, Container, IconButton, Paper, Typography} from "@mui/material";
import PhoneNumberAuthenticatorComponent from "../components/PhoneNumberAuthenticatorComponent";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {API_URL} from "../App";
import {authSuccess, setPhoneIsConnected} from "../redux/reducers/authReducer";
import {openMessage} from "../redux/reducers/messageReducer";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import EnterCodeAuthenticatorComponent from "../components/EnterCodeAuthenticatorComponent";
import {useTranslation} from "i18nano";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as React from "react";

const PhoneNumberPage = () => {
    const errorData = useSelector(state => state.authReducer.errorData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = useTranslation();

    const [errors, setErrors] = useState([]);

    const [step, setStep] = useState(0);

    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("")

    const [counter, setCounter] = useState(0);
    useEffect(() => {
        if (!errorData || !errorData.email || !errorData.password) {
            navigate("/login")
        }
    }, [])
    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const getSms = (phoneNumber) => {
        setPhoneNumber(phoneNumber);
        setLoading(true);
        axios.post(`${API_URL}/user/two-factor/phone`,
            {
                login: errorData.email,
                password: errorData.password,
                phone: phoneNumber
            }
        ).then(response => {
            if (response.data.success) {
                setStep(1);
                setCounter(60);
                localStorage.setItem("lastSendSms", new Date().getTime().toString())
            } else {
                setErrors([response.data.errors[0]]);
                //dispatch(openMessage(response.data.errors[0], "error"))
            }
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            setErrors(["Something went wrong"]);
            //dispatch(openMessage(t("Something went wrong"), "error"));
        });
    }
    const confirmPhoneNumber = (code) => {
        setLoading(true);
        axios.post(`${API_URL}/user/two-factor/phone/confirm`,
            {
                login: errorData.email,
                password: errorData.password,
                phone: phoneNumber,
                code: code
            }
        ).then(response => {
            if (response.data.success) {
                dispatch(setPhoneIsConnected(true));
                if (response.data.errors.includes("AUTHENTICATOR_IS_NOT_CONNECTED")) {
                    navigate("/authenticator")
                } else if (response.data.errors.includes("TWO_FACTOR_CODE_REQUIRED")) {
                    navigate("/two-factor-authentication")
                } else {
                    dispatch(authSuccess(response.data.data));
                    setStep(3);
                }
            } else {
                dispatch(openMessage(response.data.errors[0], "error"))
            }
            setStep(1);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
            dispatch(openMessage(t("Something went wrong"), "error"));
        });
    }
    const skipPhone = () => {
        setLoading(true);
        axios.post(`${API_URL}/user/two-factor/phone/skip`,
            {
                login: errorData.email,
                password: errorData.password
            }
        ).then(response => {
            if (response.data.success) {
                dispatch(authSuccess(response.data.data));
                setStep(3);
            } else {
                if (response.data.errors.includes("AUTHENTICATOR_IS_NOT_CONNECTED")) {
                    navigate("/authenticator")
                } else if (response.data.errors.includes("TWO_FACTOR_CODE_REQUIRED")) {
                    navigate("/two-factor-authentication")
                } else {
                    if (response.data.errors[0]) {
                        dispatch(openMessage(response.data.errors[0], "error"))
                    } else {
                        dispatch(openMessage(t("Something went wrong"), "error"))
                    }
                }
            }
            setLoading(false);
        })
    }

    const style = {padding: "40px 20px", margin: "auto", marginTop: "40px", maxWidth: "450px", position: "relative"};
    return (
        <Paper elevation={1} style={style}>
            {step !== 0 ?
                < IconButton aria-label="back" style={{position: "absolute", left: "5px", top: "5px"}}
                             onClick={() => setStep(step - 1)}>
                    <ArrowBackIcon/>
                </IconButton>
                : null
            }
            <Container>
                {step === 0 ?
                    <PhoneNumberAuthenticatorComponent errors={errors} loading={loading} onClick={getSms} onClickSkip={skipPhone}/>
                    :
                    step === 1 ?
                        <>
                            <EnterCodeAuthenticatorComponent
                                onClick={confirmPhoneNumber} loading={loading}
                                title={t("Enter Code")}
                                description={<span>{t("We have sent a verification code to your phone number")}</span>}
                            />
                            <div style={{display: 'flex', justifyContent: "center"}}>
                                {counter > 0 ?
                                    <Typography style={{marginTop: 10}} variant="body2" display="block" gutterBottom>
                                        {t("You can send code again after seconds", {seconds: `${counter}`})}
                                    </Typography>
                                    :
                                    <div style={{display:"flex", flexDirection:"column", width: "100%"}}>
                                        <Button style={{marginTop: 10}} variant="outlined" size={"large"} onClick={skipPhone}
                                                fullWidth>{t("Skip")}</Button>
                                    <Button style={{marginTop: 10}} variant="text" size={"small"}
                                            onClick={() => getSms(phoneNumber)}>{t("Send code again")}</Button>
                                    </div>
                                }
                            </div>
                        </>
                        : null
                }
            </Container>
        </Paper>
    )
}

export default PhoneNumberPage