const defaultState = {
    updateData: [],
    returnBackData: null
}

const SET_UPDATE_STATUS = "SET_UPDATE_STATUS";
const RETURN_BACK_DATA = "RETURN_BACK_DATA";

export const updateReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_UPDATE_STATUS: {
            return {...state, updateData: action.payload}
        }
        case RETURN_BACK_DATA: {
            return {...state, returnBackData: action.payload}
        }

        default:
            return state;
    }
}

export const setUpdateStatus = (payload) => ({type: SET_UPDATE_STATUS, payload})
export const returnBackData = (payload) => ({type: RETURN_BACK_DATA, payload})