import {Fragment, useState} from "react";
import {useTranslation} from "i18nano";

function useFormattingTranslation() {
    const t = useTranslation();
    return (text, values, customComponents) => formating(t, text, values, customComponents);
}

function formating(t, text, values, customComponents) {
    const boldRegex = /<b.*>.*<\/b>/gm;
    const description = t(text, values);

    let splitDescription = description.split(boldRegex);
    let matchesDescription = Array.from(description.matchAll(boldRegex));

    return splitDescription.map((a, index) => {
        let component = null;
        if (matchesDescription[index]) {
            component =
                <b>
                    {matchesDescription[index].toString().replaceAll(/<\/b>/gm, "").replaceAll(/<b.*>/gm, "")}
                </b>;
        }
        let tet = a;
        if (customComponents) {
            let cc = a.split(customComponents.name);
            if (cc && cc.length > 1) {
                tet = <Fragment>{cc[0]}{customComponents.value}{cc[1]}</Fragment>
            }
        }
        return <Fragment key={`${a}-${index}`}>{tet}{component}</Fragment>
    })
}

export default useFormattingTranslation;