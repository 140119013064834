import {Container, Typography, CircularProgress, Card, CardContent, Stack, Tooltip} from "@mui/material"
import {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ApartmentIcon from '@mui/icons-material/Apartment';
import {getPartiesThunk, getUserThunk} from "../redux/asyncAction/getUserThunk"
import {useNavigate, useParams} from "react-router-dom";
import {changeCurrentApplicant, setApplicantsNull, setPartiesNull} from "../redux/reducers/applicantsReducer"
import {useTranslation} from "i18nano";
import VerifiedIcon from '@mui/icons-material/Verified';

const ApplicantsPage = () => {
    const navigate = useNavigate();
    const t = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.applicantsReducer.user)
    const currentApplicant = useSelector(state => state.applicantsReducer.currentApplicant);
    let parties = useSelector(state => state.applicantsReducer.parties);
    const isAuth = useSelector(state => state.authReducer.isAuth)

    const status = useSelector(state => state.applicantsReducer.status);
    const {id} = useParams();

    const [loading, setLoading] = useState(true);

    useLayoutEffect(() => {
        if (isAuth === true) {
            if (!user) {
                dispatch(getUserThunk())
            }
        }
    }, [isAuth])

    useEffect(() => {
        if (user) {
            if (id) {
                dispatch(changeCurrentApplicant(id));
                localStorage.setItem("lastApplicant", id)
            } else if (localStorage.getItem("lastApplicant")) {
                dispatch(changeCurrentApplicant(localStorage.getItem("lastApplicant")));
            } else {
                const number = localStorage.getItem("lastApplicant") ? localStorage.getItem("lastApplicant") : user.applicants.length > 0 ? user.applicants[0].id : -2;

                dispatch(changeCurrentApplicant(number));
                localStorage.setItem("lastApplicant", number)
            }
        }
    }, [user])

    useEffect(() => {
        setLoading(true);
        if (id || currentApplicant !== -1 && currentApplicant != -2) {
            dispatch(setApplicantsNull);
            dispatch(getPartiesThunk(id ? id : currentApplicant));
        }
        setLoading(false);
    }, [id, currentApplicant])

    return (
        <Container sx={{paddingTop: 3}}>
            <Stack direction="row" sx={{flexWrap: "wrap", justifyContent: "center"}}>
                {status === "403" ?
                    <Typography variant={"h5"}>{t("You don't have access to this client")}</Typography> :
                    !user || loading ?
                        <CircularProgress sx={{position: "absolute", left: "calc(50% - 20px)", top: "30%"}}/> : (
                            <>
                                {
                                    parties && currentApplicant !== 0 && parties.length > 0
                                        ? parties.map((party, index) => {
                                            return (
                                                <Card onClick={() => navigate("/party/" + party.id)} key={party.id} sx={{
                                                    width: {
                                                        xs: "calc(100% - 8px)",
                                                        sm: "calc(50% - 20px)",
                                                        md: "calc(25% - 20px)",
                                                        lg: "calc(25% - 20px)",
                                                        xl: "calc(25% - 20px)"
                                                    }, height: 200, margin: "10px"
                                                }}>
                                                    <CardContent sx={{
                                                        justifyContent: "center",
                                                        display: "flex",
                                                        height: "80%",
                                                        flexDirection: "column",
                                                        alignItems: "center",
                                                        padding: "5px",
                                                        '&:hover': {
                                                            textDecoration: "underline",
                                                            cursor: "pointer"
                                                        }
                                                    }}>
                                                        {party.isDefault ? <HomeWorkIcon sx={{fontSize: 70}}/> :
                                                            <ApartmentIcon sx={{fontSize: 70}}/>}
                                                        <Typography variant="h5" justifyContent={"center"} textAlign={"center"}
                                                                    sx={{
                                                                        textOverflow: "ellipsis",
                                                                        WebkitBoxOrient: "vertical",
                                                                        WebkitLineClamp: 4,
                                                                        overflow: "hidden",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: "5"
                                                                    }}>
                                                            {party.name}
                                                            {party.isDefault && <Tooltip title="Default" >
                                                                <VerifiedIcon color={"info"} sx={{marginLeft: 1}} />
                                                            </Tooltip>}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            )
                                        })
                                        :
                                        currentApplicant == -2 || (parties && parties.length === 0)
                                            ? <Typography variant={"h5"}>{t("Empty")}</Typography>
                                            : <CircularProgress
                                                sx={{position: "absolute", left: "calc(50% - 20px)", top: "30%"}}/>

                                }
                            </>
                        )

                }
            </Stack>
        </Container>
    )
}

export default ApplicantsPage;