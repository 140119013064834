import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    InputAdornment,
    TextField
} from "@mui/material"
import React from "react"
import StatusComponent from "./StatusComponent";
import {useTranslation} from "i18nano";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const CheckboxesComponent = React.memo(({
                                            item,
                                            value,
                                            onChange,
                                            onChangeText,
                                            errorText = "",
                                            onFocus,
                                            status = "none",
                                            order = null,
                                            groupId = null,
                                            valueId
                                        }) => {
    const t = useTranslation();
    return (
        <FormControl disabled={!item.editable} required={item.required} error={errorText !== ""}>
            <div style={{display: "flex"}}>
                <FormLabel
                    id="demo-radio-buttons-group-labelqwe">{item.displayText}</FormLabel>
                <StatusComponent status={status}/>
            </div>
            <FormGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                className={"widthMaxContent"}
            >
                {
                    item.choices.map(choice => {
                        return (
                            <FormControlLabel
                                checked={value.indexOf(choice.id) !== -1}
                                key={choice.id} value={choice.id}
                                control={<Checkbox/>}
                                label={choice.text === null || choice.text === "" ? "(empty)" : choice.text}
                                className={"textWrap"}
                                onKeyPress={e => {
                                    if (value !== choice.id) {
                                        if (e.key === "Enter") {
                                            const ev = {
                                                target: {
                                                    value: choice.id.toString(),
                                                    checked: !(value.indexOf(choice.id) !== -1)
                                                }
                                            }
                                            onChange(ev, item, valueId, order, groupId);
                                        }
                                    }
                                }}
                            />
                        )
                    })
                }
                {item.other === true
                    ? <FormControlLabel
                        value={"Other"}
                        checked={value.indexOf("o:") !== -1}
                        control={<Checkbox/>} label={t("Other")}
                        onKeyPress={e => {
                            if (value.indexOf("o:") === -1) {
                                if (e.key === "Enter") {
                                    const ev = {
                                        target: {
                                            value: "Other",
                                            checked: !(value.indexOf("o:") !== -1)
                                        }
                                    }
                                    onChange(ev, item, valueId, order, groupId);
                                }
                            }
                        }}
                    />
                    : null
                }
            </FormGroup>
            {
                value.indexOf("o:") !== -1
                    ? <TextField error={errorText !== ""} value={value.substring(value.indexOf("o:") + 2)}
                                 onChange={onChangeText} label={t("Other")} variant="outlined"
                                 InputProps={{
                                     endAdornment: <InputAdornment position="end">
                                         <StatusComponent status={status}/>
                                     </InputAdornment>,
                                 }}
                                 onDrop={preventDefaultOnDropFiles}
                    />
                    : null
            }
            {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
        </FormControl>
    )
}, (prev, next) =>
    prev.value === next.value &&
    prev.errorText === next.errorText &&
    prev.status === next.status &&
    prev.item.displayText === next.item.displayText)

export default CheckboxesComponent