import {changeCurrentApplicant, getUserSuccess} from "../reducers/applicantsReducer"
import {authSuccess, checkAuthError, checkAuthSuccess} from "../reducers/authReducer"
import {axiosInstance, axiosInstanceWithToken} from "../../instances/axiosInstance";

export const authUser = (email, password) => {
    return dispatch => {
        axiosInstance.post(`user/login`,
            {
                login: email,
                password: password
            }
        )
            .then(response => {
                if (response.data.success) {
                    dispatch(authSuccess(response.data.data));
                } else {
                    response.data.email = email;
                    response.data.password = password;
                    dispatch(checkAuthError(response.data))
                    dispatch(changeCurrentApplicant(-1));
                    /*if (!response.data.errors.includes("AUTHENTICATOR_IS_NOT_CONNECTED") &&
                        !response.data.errors.includes("TWO_FACTOR_CODE_REQUIRED") &&
                        !response.data.errors.includes("PHONE_IS_NOT_CONNECTED")) {
                        dispatch(openMessage(response.data.errors, "error"))
                    }*/
                }
            })
            .catch(error => {
                console.log(error)
                error.response.data.email = email;
                error.response.data.password = password;
                dispatch(checkAuthError(error.response.data))
                dispatch(changeCurrentApplicant(-1));
                /*if (!error.response.data.errors.includes("AUTHENTICATOR_IS_NOT_CONNECTED") &&
                    !error.response.data.errors.includes("TWO_FACTOR_CODE_REQUIRED") &&
                    !error.response.data.errors.includes("PHONE_IS_NOT_CONNECTED")) {
                    dispatch(openMessage(error.response.data.errors, "error"))
                }*/
            });
    }
}

export const checkAuthUser = () => {
    return dispatch => {
        axiosInstanceWithToken.get(`user`)
            .then(response => {
                dispatch(checkAuthSuccess())
                dispatch(getUserSuccess(response.data.data));
            });
    }
}