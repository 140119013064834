import {Container, Typography, Button, Stack} from "@mui/material";
import QRCode from "react-qr-code";
import {useTranslation} from "i18nano";
import useFormattingTranslation from "../hooks/useFormattingTranslations";

const ScanCodeAuthenticatorComponent = ({authenticatorUri, sharedKey, onClick}) => {
    const t = useTranslation();
    const tf = useFormattingTranslation();
    const styleSharedKey = {background: "#dbedff", color: "black", padding: "1px 10px", borderRadius: "5px"}

    return (
        <Container>
            <Stack
                spacing={2}
                justifyContent={'center'}
            >

                <Typography variant="h4" align={'center'}>
                    Scan Code
                </Typography>
                <Typography variant="body1" gutterBottom align={"center"}>
                    {tf("Scan QR Code description", null, {
                        name: "[[sharedKey]]",
                        value: <b style={styleSharedKey}>{sharedKey}</b>
                    })}
                </Typography>

                <QRCode style={{margin: "auto", marginTop: 20, marginBottom: 20}} value={authenticatorUri}/>

                <Button variant="contained" onClick={onClick}>{t("Next")}</Button>
            </Stack>
        </Container>
    )
}

export default ScanCodeAuthenticatorComponent