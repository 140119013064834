import {Container, Paper} from "@mui/material";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../App";
import {authSuccess} from "../redux/reducers/authReducer";
import {openMessage} from "../redux/reducers/messageReducer";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import EnterCodeAuthenticatorComponent from "../components/EnterCodeAuthenticatorComponent";
import {useTranslation} from "i18nano";
import useFormattingTranslation from "../hooks/useFormattingTranslations";

const TwoFactorAuthentication = () => {
    const errorData = useSelector(state => state.authReducer.errorData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = useTranslation();
    const tf = useFormattingTranslation();

    const [loading, setLoading] = useState(false);

    const sendCode = (code) => {
        setLoading(true)
        axios.post(`${API_URL}/user/login`,
            {
                login: errorData.email,
                password: errorData.password,
                code: code,
                twoFactorProvider: 0
            }
        ).then(response => {
            if (response.data.success) {
                dispatch(authSuccess(response.data.data));
            } else {
                dispatch(openMessage(response.data.errors[0], "error"))
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false);
            dispatch(openMessage(t("Something went wrong"), "error"));
        });
    }
    useEffect(() => {
        if (!errorData || !errorData.email || !errorData.password) {
            navigate("/login")
        }
    }, [])
    const style = {padding: "40px 20px", margin: "auto", marginTop: "40px", maxWidth: "450px"};

    return (
        <Paper elevation={1} style={style}>
            <Container>
                <EnterCodeAuthenticatorComponent
                    onClick={sendCode} loading={loading}
                    title={t("Two factor Authentication")}
                    description={tf("Your login is protected with an Authenticator")}
                />
                {errorData && errorData.data && errorData.data.availableTwoFactorProviders.includes(1) ?
                    <NavLink to={"/two-factor-authentication/phone"}
                             style={{display: "block", width: "100%", textAlign: "center", marginTop: "10px"}}>
                        {t("Use phone number instead")}
                    </NavLink>
                    : null
                }
            </Container>
        </Paper>
    )
}

export default TwoFactorAuthentication;