import AuthenticatorDialog from "./AuthenticatorDialog";

const GoogleAuthenticatorDialog = ({open, onClose}) => {
    return (
        <AuthenticatorDialog open={open} onClose={onClose} title={"Google Authenticator"}
                             playMarketLink={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=ru&gl=US"}
                             appStoreLink={"https://apps.apple.com/ru/app/google-authenticator/id388497605"}
        />
    )
}
export default GoogleAuthenticatorDialog