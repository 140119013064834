import {changeCurrentApplicant, getParties, getUserSuccess, setStatus} from "../reducers/applicantsReducer";
import {checkAuthError} from "../reducers/authReducer";
import {openMessage} from "../reducers/messageReducer";
import {axiosInstanceWithToken} from "../../instances/axiosInstance";

export const getUserThunk = () => {
    return dispatch => {
        axiosInstanceWithToken.get(`user`)
            .then(response => {
                dispatch(getUserSuccess(response.data.data));
            });
    }
}

export const getPartiesThunk = (id) => {
    return dispatch => {
        axiosInstanceWithToken.get(`applicant/${id}`)
            .then(response => {
                dispatch(setStatus("200"))
                dispatch(getParties({
                    parties: response.data.data.parties,
                    applicantId: id,
                    isLocked: response.data.data.isLocked
                }));
            })
            .catch(error => {
                try {
                    if (error?.response?.status === 403) {
                        dispatch(setStatus("403"))
                        //dispatch(openMessage("You don't have access to this client", 'error'))
                    } else {
                        dispatch(openMessage("The request failed", 'error'))
                    }
                } catch (e) {
                    console.log(e);
                }
            })
    }
}