import {useDispatch, useSelector} from "react-redux";
import ScanCodeAuthenticatorComponent from "../components/ScanCodeAuthenticatorComponent";
import {useEffect, useState} from "react";
import {Container, IconButton, Paper} from "@mui/material";
import AuthenticatorAddedComponent from "../components/AuthenticatorAddedComponent";
import DownloadAuthenticatorComponent from "../components/DownloadAuthenticatorComponent";
import axios from "axios";
import {API_URL} from "../App";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EnterCodeAuthenticatorComponent from "../components/EnterCodeAuthenticatorComponent";
import {openMessage} from "../redux/reducers/messageReducer";
import {authSuccess, setAuthenticatorIsConnected} from "../redux/reducers/authReducer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "i18nano";
import useFormattingTranslations from "../hooks/useFormattingTranslations";

const AuthenticatorPage = () => {
    const errorData = useSelector(state => state.authReducer.errorData);
    const dispatch = useDispatch();
    let [step, setStep] = useState(0);
    const navigate = useNavigate();
    const t = useTranslation();
    const tf = useFormattingTranslations()

    const [sendCodeLoading, setSendCodeLoading] = useState(false);

    const sendCode = (code) => {
        setSendCodeLoading(true);
        axios.post(`${API_URL}/user/two-factor`,
            {
                login: errorData.email,
                password: errorData.password,
                code: code
            }
        ).then(response => {
            if (response.data.success) {
                dispatch(setAuthenticatorIsConnected(true));
                if (errorData.errors.includes("PHONE_IS_NOT_CONNECTED")) {
                    navigate("/phoneAuthenticator")
                } else {
                    dispatch(authSuccess(response.data.data));
                    setStep(3);
                }
            } else {
                dispatch(openMessage(response.data.errors[0], "error"))
            }
            setSendCodeLoading(false);
        }).catch(() => {
            setSendCodeLoading(false);
            dispatch(openMessage(t("Something went wrong"), "error"));
        });
    }

    useEffect(() => {
        if (!errorData || !errorData.email || !errorData.password) {
            navigate("/login")
        }
    }, [])

    const style = {padding: "40px 20px", margin: "auto", marginTop: "40px", maxWidth: "450px", position: "relative"};
    return (
        <Paper elevation={1} style={style}>
            {step !== 0 ?
                < IconButton aria-label="back" style={{position: "absolute", left: "5px", top: "5px"}}
                             onClick={() => setStep(step - 1)}>
                    <ArrowBackIcon/>
                </IconButton>
                : null
            }
            <Container>
                {
                    step === 0 ? <DownloadAuthenticatorComponent onClick={() => setStep(1)}/> :
                        step === 1 ? <ScanCodeAuthenticatorComponent
                                authenticatorUri={errorData.data.authenticatorUri}
                                sharedKey={errorData.data.sharedKey}
                                onClick={() => setStep(2)}/> :
                            step == 2 ? <EnterCodeAuthenticatorComponent
                                    onClick={sendCode}
                                    loading={sendCodeLoading}
                                    title={"Enter Code"}
                                    description={
                                        <span>{tf("Once you have scanned the QR code")}</span>}
                                /> :
                                step === 3 ? <AuthenticatorAddedComponent onClick={() => navigate("/login")}/> : null
                }
            </Container>
        </Paper>
    );
}

export default AuthenticatorPage