import {checkAuthError} from "../reducers/authReducer";
import {getCurrentPartyError, getCurrentPartySuccess,} from "../reducers/partyReducer";
import {openMessage} from "../reducers/messageReducer";
import {changeCurrentApplicant} from "../reducers/applicantsReducer";
import {axiosInstanceWithToken} from "../../instances/axiosInstance";

export const getCurrentParty = (id) => {
    return dispatch => {
        let language = localStorage.getItem("lang");
        if (!language) language = "en";
        axiosInstanceWithToken.get(`party/${language}/${id}`)
            .then(response => {
                if (response.data.errors && response.data.errors.length > 0) {
                    dispatch(getCurrentPartyError());
                    dispatch(changeCurrentApplicant(-1));
                    dispatch(openMessage("The request failed", 'error'))
                    return;
                }

                dispatch(getCurrentPartySuccess(response.data.data));
            })
            .catch(error => {
                try {
                    dispatch(getCurrentPartyError());
                    if (error?.response?.status === 403) {
                        dispatch(checkAuthError());
                        dispatch(changeCurrentApplicant(-1));
                        dispatch(openMessage("You don't have access to this party", 'error'))
                    } else {
                        dispatch(changeCurrentApplicant(-1));
                        dispatch(openMessage("The request failed", 'error'))
                    }
                } catch (e) {
                    dispatch(changeCurrentApplicant(-1));
                    //dispatch(openMessage("The request failed", 'error'))
                }
            })
    }
}

/*export const copyParty = (currentPartyId, copyPartyId) => {
    return dispatch => {
        dispatch(setCopyLoading(true));

        const token = localStorage.getItem("token");
        if (!token) {
            dispatch(checkAuthError());
            dispatch(changeCurrentApplicant(-1));
        }

        axios.post(`${API_URL}/party/${currentPartyId}/copy/${copyPartyId}`, null, {
            headers: {"authorization": `Bearer ${token}`}
        })
            .then(response => {
                dispatch(getCurrentParty(currentPartyId));
            })
            .catch(error => {
                console.log(error.message)
                if (error.response.status === 401 || error.response.status === 403) {
                    dispatch(checkAuthError());
                    dispatch(changeCurrentApplicant(-1));
                    dispatch(openMessage("You don't have access", 'error'))
                } else {
                    dispatch(openMessage("The request failed", 'error'))
                }
                console.log(error);
            })
    }
}*/