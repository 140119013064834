const defaultState = {
    user: null,
    currentApplicant: -1,
    parties: null,
    isLocked: false,
    status: "none"
}

const GET_USER_SUCCESS = "GET_USER_SUCCESS";
const GET_PARTIES = "GET_PARTIES";
const CHANGE_CURRENT_APPLICANT = "CHANGE_CURRENT_APPLICANT"
const SET_APPLICANTS_NULL = "SET_APPLICANTS_NULL";
const SET_STATUS = "SET_STATUS";
const SET_PARTIES_NULL = "SET_PARTIES_NULL"

export const applicantsReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_USER_SUCCESS: {
            return {...state, user: action.payload}
        }
        case CHANGE_CURRENT_APPLICANT: {
            return {...state, currentApplicant: action.payload, parties: null}
        }

        case GET_PARTIES: {
            return {...state, parties: action.payload.parties, isLocked: action.payload.isLocked}
        }
        case SET_APPLICANTS_NULL: {
            return {...state, parties: null, user: null, currentApplicant: -1}
        }
        case SET_STATUS: {
            return {...state, status: action.payload}
        }
        case SET_PARTIES_NULL:{
            return {...state, parties: null}
        }

        default:
            return state;
    }
}

export const getUserSuccess = (payload) => ({type: GET_USER_SUCCESS, payload})
export const getParties = (payload) => ({type: GET_PARTIES, payload})
export const changeCurrentApplicant = payload => ({type: CHANGE_CURRENT_APPLICANT, payload})
export const setApplicantsNull = () => ({type: SET_APPLICANTS_NULL})
export const setStatus = (payload) => ({type: SET_STATUS, payload})
export const setPartiesNull = () => ({type: SET_PARTIES_NULL});
