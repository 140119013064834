import {axiosInstance} from "../instances/axiosInstance";

export class TokenApiService {
    static Revoke(token) {
        return axiosInstance.post(`token/revoke`, null, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
    }
}