import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useDebouncedCallback} from 'use-debounce';
import {axiosInstanceWithToken} from "../instances/axiosInstance";
import {v4} from 'uuid';
import {getCurrentParty} from "../redux/asyncAction/getCurrentParty";
import {answerUpdate, modifiedParty} from "../redux/asyncAction/answerThunk"
import {getPartiesThunk} from "../redux/asyncAction/getUserThunk";
import {setPartyNull} from "../redux/reducers/partyReducer";
import {openMessage} from "../redux/reducers/messageReducer";
import {changeCurrentApplicant} from "../redux/reducers/applicantsReducer";
import {setNewFile} from "../redux/reducers/filesReducer";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {Container, Typography, Stack, Button, IconButton, Card, CardContent, CircularProgress, Backdrop, List, ListItem,
    ListItemButton, ListItemText, Box, Paper, Link, TextField, Fab} from "@mui/material"
import Popover from '@mui/material/Popover';
import PopupState, {bindTrigger, bindPopover} from 'material-ui-popup-state';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {API_URL} from "../App";
import {FILE_TYPES, FILE_SIZE} from "../fileConstants"
import CommonComponent from "../components/CommonComponent";
import {checkAuthError} from "../redux/reducers/authReducer";
import {LoadingButton} from "@mui/lab";
import useNetwork from "../hooks/useNetwork";
import generateState from "../utils/generateState";
import {useTranslation} from "i18nano";
import SearchFloatButton from "../components/SearchFloatButton";
import PresetComponent from "../components/PresetComponent";

const PartyPage = () => {
    const navigate = useNavigate()
    const {id} = useParams();
    const dispatch = useDispatch();
    const t = useTranslation();

    const [errorUpdates, setErrorUpdates] = useState([]);

    const [openDrawer, setOpenDrawer] = useState(false);

    const {online} = useNetwork();
    useEffect(() => {
        if (online) {
            if (errorUpdates.length > 0) {
                errorUpdates.forEach(data => {
                    dataSendWithoutDebounce(data.text, {id: data.questionId}, data.answerId, data.groupId, data.groupOrder);
                })
                setErrorUpdates([]);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [online])

    const [isGetValue, setIsGetValue] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [thisApplicant, setThisApplicant] = useState(true);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [values, setValues] = useState([]);
    const [valuesViews, setValuesViews] = useState([]);

    const [dragValues, setDragValues] = useState({});
    const [changeObject, setChangeObject] = useState({});

    const [searchText, setSearchText] = useState("");

    const changeValues = (id, object) => {
        setValues(prev => ({...prev, [id]: {...prev[id], ...object}}))
    };
    const changeValuesArray = (questionId, answerId, object) => {
        setValues(prev => ({
            ...prev,
            [questionId]: {
                ...prev[questionId], error: false, array: prev[questionId].array.map(pr => {
                    if (pr.id === answerId) return {...prev[questionId].array.find(v => v.id === answerId), ...object};
                    else return pr;
                })
            }
        }));
    }
    const changeValuesViews = (groupId, order, questionId, object) => {
        setValuesViews(prev => (
            {
                ...prev,
                [groupId]:
                    {
                        ...prev[groupId],
                        [order]:
                            {
                                ...prev[groupId][order],
                                [questionId]: {
                                    ...prev[groupId][order][questionId],
                                    ...object
                                }
                            }
                    }
            }));
    }
    const changeValuesViewArray = (groupId, order, questionId, answerId, object) => {
        setValuesViews(prev => ({
            ...prev,
            [groupId]:
                {
                    ...prev[groupId],
                    [order]: {
                        ...prev[groupId][order],
                        [questionId]: {
                            ...prev[groupId][order][questionId],
                            error: false,
                            array: prev[groupId][order][questionId].array.map(pr => {
                                if (pr.id === answerId) return {
                                    ...prev[groupId][order][questionId].array.find(a => a.id === answerId),
                                    ...object
                                }
                                else return pr;
                            })
                        }
                    }
                }
        }));
    }

    const user = useSelector(state => state.applicantsReducer.user)
    const currentApplicant = useSelector(state => state.applicantsReducer.currentApplicant);
    const isLocked = useSelector(state => state.applicantsReducer.isLocked);
    const parties = useSelector(state => state.applicantsReducer.parties);

    const party = useSelector(state => state.partyReducer.currentParty);
    const reload = useSelector(state => state.partyReducer.reload);
    const copyLoading = useSelector(state => state.partyReducer.copyLoading);

    const updateData = useSelector(state => state.updateReducer.updateData);
    const returnBackData = useSelector(state => state.updateReducer.returnBackData);

    const findItem = (itemId, order, groupId) => {
        if (order && groupId) {
            return party.presets(p => p.items.find(a => a.id === groupId && a.views)).items.find(a => a.id === groupId && a.views)
                .views.find(v => v.order === order)
                .questions.find(q => q.id === itemId);
        }

        return party.presets.find(p => p.items.find(i => i.id === itemId)).items.find(i => i.id === itemId);
    }

    useEffect(() => {
        if (returnBackData) {
            if (returnBackData.groupId) {
                let newValues = JSON.parse(JSON.stringify(valuesViews[returnBackData.groupId][returnBackData.groupOrder][returnBackData.questionId]));
                const item = findItem(returnBackData.questionId, returnBackData.groupOrder, returnBackData.groupId);
                //party.items.find(i => i.id === returnBackData.questionId && i.views).views[0].questions.find(q => q.id === returnBackData.questionId);

                if (item.allowMultiple || item.questionType === 8) {

                    newValues.array.push({
                        value: returnBackData.oldText,
                        id: returnBackData.answerId,
                        required: item.required,
                        errorText: "",
                        order: returnBackData.order ?? 0
                    })
                } else {
                    newValues = {
                        value: returnBackData.oldText,
                        id: returnBackData.answerId,
                        required: item.required,
                        errorText: "",
                        order: returnBackData.order ?? 0
                    }
                }
                setValuesViews(prev => ({
                    ...prev,
                    [returnBackData.groupId]:
                        {
                            ...prev[returnBackData.groupId],
                            [returnBackData.groupOrder]: {
                                ...prev[returnBackData.groupId][returnBackData.groupOrder],
                                [returnBackData.questionId]: newValues
                            }
                        }
                }));

            } else {
                let newValues = JSON.parse(JSON.stringify(values[returnBackData.questionId]));
                const item = findItem(returnBackData.questionId);// party.items.find(i => i.id === returnBackData.questionId);

                if (item.allowMultiple || item.questionType === 8) {
                    newValues.array.push({
                        value: returnBackData.oldText,
                        id: returnBackData.answerId,
                        required: item.required,
                        errorText: "",
                        order: returnBackData.order ?? 0
                    })
                } else {
                    newValues = {
                        value: returnBackData.oldText,
                        id: returnBackData.answerId,
                        required: item.required,
                        errorText: "",
                        order: returnBackData.order ?? 0
                    }
                }
                setValues(prev => ({...prev, [returnBackData.questionId]: newValues}))
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnBackData])

    const changeStatus = (groupId, order, questionId, answerId, status) => {
        if (groupId) {
            if (valuesViews[groupId] && valuesViews[groupId][order] && valuesViews[groupId][order][questionId]
                && valuesViews[groupId][order][questionId].status !== status) {
                if (valuesViews[groupId][order][questionId].array) {
                    changeValuesViewArray(groupId, order, questionId, answerId, {status: status});
                } else {
                    changeValuesViews(groupId, order, questionId, {status: status})
                }
            }
        } else {
            if (values[questionId] && values[questionId].status !== status) {
                if (values[questionId].array) {
                    changeValuesArray(questionId, answerId, {status: status});
                } else {
                    changeValues(questionId, {status: status});
                }
            }
        }
    }

    const updateStatusFiles = (status, itemId, order = null, groupId = null,) => {
        if (groupId) {
            setDragValues(prev => ({
                ...prev,
                [groupId]: {...prev[groupId], [order]: {[itemId]: {status: status}}}
            }))
        } else {
            setDragValues(prev => ({...prev, [itemId]: {status: status}}))
        }
    }

    useEffect(() => {
        const d = updateData;
        if (d.attachments && d.attachments.length > 0) {
            updateStatusFiles("none", d.questionId, d.groupOrder, d.groupId)
            return;
        }
        changeStatus(d.groupId, d.groupOrder, d.questionId, d.id, d.status)
        if (d.status === "error") {
            setErrorUpdates(prev => [...prev, d]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData])

    useEffect(() => {
        dispatch(setPartyNull());
        const focusHandler = () => {
            dispatch(modifiedParty(id));
        };
        focusHandler();
        window.addEventListener("focus", focusHandler);
        window.addEventListener('beforeunload', () => {
            localStorage.setItem("scroll", window.scrollY);
        });

        const navigationType = () => {
            if (window.performance) {
                if (window.performance.getEntriesByType('navigation').length) {
                    return window.performance.getEntriesByType('navigation')[0].type
                }
            }
        }

        let perfEntries = navigationType();
        if (perfEntries) {
            if (perfEntries === "reload") {
                let timerId = setInterval(() => {
                    let scrollY = localStorage.getItem("scroll");
                    if (scrollY) {
                        window.scrollTo(0, scrollY);
                        if (document.scrollY !== scrollY) {
                            clearInterval(timerId);
                        }
                    }
                }, 500);
            }
        }
        let element = document.getElementById("navByModule");

        const scrollEventHandler = e => {
            if (!element) {
                element = document.getElementById("navByModule");
            }

            if (window.scrollY <= 90) {
                let a = window.scrollY;
                element.style.transform = `translateY(-${a}px)`;
                element.style.maxHeight = `calc(100vh - ${90 - a}px)`
            } else {
                element.style.transform = `translateY(-90px)`;
                element.style.maxHeight = `100vh`
                element.style.height = `100vh`;
            }
        };
        document.addEventListener("scroll", scrollEventHandler);

        return () => {
            dispatch(setPartyNull());
            dispatch(setNewFile(null));
            window.removeEventListener("focus", focusHandler);
            document.removeEventListener("scroll", scrollEventHandler);
            localStorage.removeItem("scroll");
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (parties && party && party !== -1) {
            if (!parties.find(p => p.name === party.name)) {
                setThisApplicant(false)
                setValues([]);
                setValuesViews([]);
            }
        }// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parties])
    useEffect(() => {
        if (user) {
            if (currentApplicant !== -1) {
                dispatch(getPartiesThunk(currentApplicant))
            } else {
                const number = localStorage.getItem("lastApplicant") ? localStorage.getItem("lastApplicant") : user.applicants[0].id;
                dispatch(changeCurrentApplicant(number));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApplicant, user])
    useEffect(() => {
        setValues([]);
        setValuesViews([]);
        setIsGetValue(false);

        dispatch(getCurrentParty(id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    useEffect(() => {
        if (party !== -1) {
            setIsGetValue(false);
            setValues([]);
            setValuesViews([]);

            if (party) {
                setIsSubmit(party.isSubmitted);
                const {newValue, newValuesViews} = generateState(party);
                setValues(newValue);
                setValuesViews(newValuesViews);
                setIsGetValue(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [party]);
    useEffect(() => {
        if (reload === true) {
            dispatch(getCurrentParty(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    const debounced = useDebouncedCallback(
        (data) => {
            changeStatus(data.groupId, data.groupOrder, data.questionId, data.answerId, "updating")
            dispatch(answerUpdate(data));
            setChangeObject({});
        }, 500
    );

    const dataSend = (value, item, answerId, groupId = null, orderId = 0, orderInArray = 0, oldText = "") => {
        orderId = parseInt(orderId);

        const answer = {
            partyId: party.id,
            answerId: answerId === undefined ? v4() : answerId,
            questionId: item.id,
            text: value.toString(),
            questionType: item.questionType,
            groupOrder: orderId,
            order: orderInArray,
            timestamp: new Date().getTime(),
        }
        if (oldText !== "") answer.oldText = oldText;
        if (groupId) {
            answer.groupId = groupId;
        }
        if (debounced.isPending() && changeObject.answerId !== answer.answerId) {
            debounced.flush();
        }
        setChangeObject(answer);
        debounced(answer);
    }

    const dataSendWithoutDebounce = (value, item, answerId, groupId = null, orderId = 0) => {
        orderId = parseInt(orderId);
        const formData = {};
        formData.partyId = party.id;
        formData.answerId = answerId === undefined ? v4() : answerId;
        formData.questionId = item.id;
        formData.text = value.toString();
        formData.questionType = item.questionType;
        formData.groupOrder = orderId;
        formData.timestamp = new Date().getTime();
        if (groupId) {
            formData.groupId = groupId;
        }

        changeStatus(formData.groupId, formData.groupOrder, formData.questionId, formData.answerId, "updating")
        dispatch(answerUpdate(formData));
    }
    const dataSendFile = (value = "", item, answerId, groupId = null, orderId = 0, isDelete = false) => {
        orderId = parseInt(orderId);
        let itemId = parseInt(item.id);
        const formData = {};
        formData.partyId = party.id;
        formData.answerId = answerId === undefined ? v4() : answerId;
        formData.questionId = itemId;
        formData.text = "";
        formData.questionType = item.questionType;
        formData.groupOrder = orderId;
        formData.timestamp = new Date().getTime();
        if (groupId) {
            formData.groupId = groupId;
        }
        formData.attachments = [];

        if (!isDelete) {
            if (value !== "") {

                if (groupId) {
                    valuesViews[groupId][orderId][item.id].array.sort((a, b) => a.index - b.index).forEach(item => {
                        formData.attachments = [...formData.attachments, {id: item.attachmentId}]
                    })
                    value.forEach(val => {
                        formData.attachments = [...formData.attachments, {token: val}]
                    })
                } else {
                    if (values[item.id]) {
                        values[item.id].array.sort((a, b) => a.index - b.index).forEach(item => {
                            formData.attachments = [...formData.attachments, {id: item.attachmentId}]
                        })
                    }
                    value.forEach(val => {
                        formData.attachments = [...formData.attachments, {token: val}]
                    })
                }

                dispatch(answerUpdate(formData, true));
            } else {
                if (groupId) {
                    valuesViews[groupId][orderId][item.id].array.sort((a, b) => a.index - b.index).forEach(item => {
                        formData.attachments = [...formData.attachments, {id: item.attachmentId}]
                    })
                } else {
                    values[item.id].array.sort((a, b) => a.index - b.index).forEach(item => {
                        formData.attachments = [...formData.attachments, {id: item.attachmentId}]
                    })
                }

                dispatch(answerUpdate(formData));
            }
        } else {
            if (groupId) {
                valuesViews[groupId][orderId][item.id].array.forEach(item => {
                    if (item.attachmentId !== value) {
                        formData.attachments = [...formData.attachments, {id: item.attachmentId}]
                    }
                })
                setValuesViews(prev => (
                    {
                        ...prev,
                        [groupId]:
                            {
                                ...prev[groupId],
                                [orderId]:
                                    {
                                        ...prev[groupId][orderId],
                                        [item.id]: {
                                            ...prev[groupId][orderId][item.id],
                                            array: prev[groupId][orderId][item.id].array.filter(p => p.attachmentId !== value)
                                        }
                                    }
                            }
                    }))
            } else {
                values[item.id].array.forEach(item => {
                    if (item.attachmentId !== value) {
                        formData.attachments = [...formData.attachments, {id: item.attachmentId}]
                    }
                })
                setValues(prev => ({
                    ...prev,
                    [item.id]: {...prev[item.id], array: prev[item.id].array.filter(p => p.attachmentId !== value)}
                }));
            }
            dispatch(answerUpdate(formData));
        }

    }

    const handleChangeText = (e, item, answerId, orderId, groupId) => {
        groupId && orderId
            ? changeValuesViews(groupId, orderId, item.id, {
                value: e.target.value,
                id: answerId,
                required: item.required,
                errorText: ""
            })
            : changeValues(item.id, {value: e.target.value, id: answerId, required: item.required, errorText: ""});
        dataSend(e.target.value, item, answerId, groupId, orderId);
    }
    const handleChangeDate = (e, item, answerId, orderId, groupId) => {
        if (!e) e = "";
        if (e.toString() === "Invalid Date") {
            groupId && orderId
                ? changeValuesViews(groupId, orderId, item.id, {
                    value: "DateNoValidSecret",
                    id: answerId,
                    required: item.required,
                    errorText: ""
                })
                : changeValues(item.id, {
                    value: "DateNoValidSecret",
                    id: answerId,
                    required: item.required,
                    errorText: ""
                });
        } else {
            groupId && orderId
                ? changeValuesViews(groupId, orderId, item.id, {
                    value: e,
                    id: answerId,
                    required: item.required,
                    errorText: ""
                })
                : changeValues(item.id, {value: e, id: answerId, required: item.required, errorText: ""});

            let date = e !== "" ? (new Date(Date.parse(e.toString()))) : "";
            let dateToServer = "";
            if (date !== "") {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
                console.log(date.toISOString());
                dateToServer = `${date.getFullYear()}-${date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}T00:00:00.000`;
            }

            dataSend(dateToServer, item, answerId, groupId, orderId);
        }
    }
    const handleChangeRadio = (e, item, answerId, orderId, groupId) => {
        groupId && orderId
            ? changeValuesViews(groupId, orderId, item.id, {
                value: e.target.value,
                id: answerId,
                required: item.required,
                errorText: ""
            })
            : changeValues(item.id, {value: e.target.value, id: answerId, required: item.required, errorText: ""});
        dataSendWithoutDebounce(e.target.value, item, answerId, groupId, orderId);
    }
    const handleChangeRadioWithOther = (e, item, answerId, orderId, groupId) => {
        if (e.target.value === "Other") {
            groupId && orderId
                ? changeValuesViews(groupId, orderId, item.id, {
                    value: "o:",
                    id: answerId,
                    required: item.required,
                    errorText: ""
                })
                : changeValues(item.id, {value: "o:", id: answerId, required: item.required, errorText: ""});
            dataSendWithoutDebounce("o:", item, answerId, groupId, orderId);
        } else {
            groupId && orderId
                ? changeValuesViews(groupId, orderId, item.id, {
                    value: e.target.value,
                    id: answerId,
                    required: item.required,
                    errorText: ""
                })
                : changeValues(item.id, {value: e.target.value, id: answerId, required: item.required, errorText: ""});
            dataSendWithoutDebounce(e.target.value, item, answerId, groupId, orderId);
        }
    }
    const handleChangeRadioWithOtherChangeText = (e, item, answerId, orderId, groupId) => {
        groupId && orderId
            ? changeValuesViews(groupId, orderId, item.id, {
                value: "o:" + e.target.value,
                id: answerId,
                required: item.required,
                errorText: ""
            })
            : changeValues(item.id, {
                value: "o:" + e.target.value,
                id: answerId,
                required: item.required,
                errorText: ""
            });
        dataSend("o:" + e.target.value, item, answerId, groupId, orderId);
    }
    const sendNewValueCheckbox = (value, item, answerId, orderId, groupId) => {
        value = value.replace(",,", ",")
        if (value.startsWith(",")) {
            value = value.substring(1)
        }
        if (value.endsWith(",")) {
            value = value.substring(0, value.length - 1)
        }
        groupId && orderId
            ? changeValuesViews(groupId, orderId, item.id, {
                value: value,
                id: answerId,
                required: item.required,
                errorText: ""
            })
            : changeValues(item.id, {value: value, id: answerId, required: item.required, errorText: ""});
        dataSendWithoutDebounce(value, item, answerId, groupId, orderId);
    }

    const handleChangeCheckbox = (e, item, answerId, orderId, groupId) => {
        let newValue = groupId && orderId ? valuesViews[groupId][orderId][item.id].value : values[item.id].value;
        e.target.checked
            ? newValue += "," + e.target.value
            : newValue = newValue.replace(`${e.target.value}`, "");
        sendNewValueCheckbox(newValue, item, answerId, orderId, groupId);
    }
    const handleChangeCheckboxWithOther = (e, item, answerId, orderId, groupId) => {
        let newValue = groupId && orderId ? valuesViews[groupId][orderId][item.id].value : values[item.id].value;

        if (e.target.checked) {
            if (e.target.value !== "Other") {
                newValue = e.target.value + "," + newValue;
            } else {
                newValue += ",o:";
            }
        } else {
            if (e.target.value !== "Other") {
                newValue = newValue.replace(`${e.target.value}`, "");
            } else {
                newValue = newValue.substring(0, newValue.indexOf(",o:"))
            }
        }
        sendNewValueCheckbox(newValue, item, answerId, orderId, groupId);
    }
    const handleChangeCheckboxWithOtherChangeText = (e, item, answerId, orderId, groupId) => {
        let newValue = groupId && orderId ? valuesViews[groupId][orderId][item.id].value : values[item.id].value;
        newValue = newValue.substring(0, newValue.indexOf(",o:") + 3)
        newValue += e.target.value;

        groupId && orderId
            ? changeValuesViews(groupId, orderId, item.id, {
                value: newValue,
                id: answerId,
                require: item.required,
                errorText: ""
            })
            : changeValues(item.id, {value: newValue, id: answerId, required: item.required, errorText: ""});
        dataSend(newValue, item, answerId, groupId, orderId);
    }
    const handleMultipleText = (e, item, answerId, orderId, groupId, orderInArray = 0) => {
        groupId && orderId
            ? changeValuesViewArray(groupId, orderId, item.id, answerId, {
                value: e.target.value,
                id: answerId,
                required: item.required,
                errorText: "",
                order: orderInArray
            })
            : changeValuesArray(item.id, answerId, {
                value: e.target.value,
                id: answerId,
                required: item.required,
                errorText: "",
                order: orderInArray
            });
        dataSend(e.target.value, item, answerId, groupId, orderId, orderInArray);
    }
    const handleAddNewTextField = (itemId, orderId, groupId) => {
        if (groupId && orderId) {
            const question = findItem(itemId, 0, groupId);
            //party.items.find(a => a.id === groupId && a.views).views[0].questions.find(q => q.id === itemId)
            setValuesViews(prev => (
                {
                    ...prev, [groupId]:
                        {
                            ...prev[groupId], [orderId]: {
                                ...prev[groupId][orderId],
                                [itemId]:
                                    {
                                        ...prev[groupId][orderId][itemId],
                                        array: prev[groupId][orderId][itemId].array.concat({
                                            value: "", id: v4(), required: question.required, errorText: '',
                                            order: prev[groupId][orderId][itemId].array[prev[groupId][orderId][itemId].array.length - 1] ?
                                                prev[groupId][orderId][itemId].array[prev[groupId][orderId][itemId].array.length - 1].order + 1 : 0
                                        })
                                    }
                            }
                        }
                }))
        } else {
            const question = party.presets.find(p => p.items.find(a => a.id === itemId)).items.find(a => a.id === itemId);
            setValues(prev => (
                {
                    ...prev,
                    [itemId]: {
                        ...prev[itemId], array: prev[itemId].array.concat({
                            value: "", id: v4(), required: question.required, errorText: "",
                            order: prev[itemId].array[prev[itemId].array.length - 1] ? prev[itemId].array[prev[itemId].array.length - 1].order + 1 : 0
                        })
                    }
                }
            ))
        }
    }
    const handleClickRemoveTextField = (itemId, id, orderId, groupId) => {
        if (groupId && orderId) {
            const v = valuesViews[groupId][orderId][itemId].array.find(a => a.id === id);
            setValuesViews(prev => (
                {
                    ...prev, [groupId]:
                        {
                            ...prev[groupId],
                            [orderId]: {
                                ...prev[groupId][orderId],
                                [itemId]: {
                                    ...prev[groupId][orderId][itemId],
                                    array: prev[groupId][orderId][itemId].array.filter(a => a.id !== id)
                                }
                            }
                        }
                }
            ))
            dataSend("", findItem(itemId, orderId ?? 0, groupId),
                //party.items.find(a => a.id === groupId && a.views).views.find(v => v.order === 0).questions.find(q => q.id === itemId),
                id, groupId, orderId, v.order, v.value)
        } else {
            const v = values[itemId].array.find(a => a.id === id);
            dataSend("", findItem(itemId), id, null, 0, v.order, v.value)
            setValues(prev => ({
                ...prev,
                [itemId]: {...prev[itemId], array: prev[itemId].array.filter(a => a.id !== id)}
            }));
        }
    }

    /*const [openDialog, setOpenDialog] = useState(false);
    const [copyValue, setCopyValue] = useState('');
    const handleClose = () => setOpenDialog(false);
    const onChangeCopy = (e) => {
        setCopyValue(e.target.value);
        setOpenDialog(true);
    }
    const onClickContinueButton = () => {
        dispatch(copyParty(party.id, copyValue));
        setOpenDialog(false);
    }
    useEffect(() => {
        if (copyValue !== '' && copyLoading === false) {
            dispatch(openMessage("Copy is success", 'success'))
        }
    }, [copyLoading])*/

    const dragStartFileHandler = (e, itemId, order, groupId) => {
        e.preventDefault();
        if (groupId) {
            if (dragValues[groupId] &&
                dragValues[groupId][order] &&
                dragValues[groupId][order][itemId] &&
                dragValues[groupId][order][itemId].status === "drag") return;
        } else {
            if (dragValues[itemId] && dragValues[itemId].status === "drag") return;
        }
        updateStatusFiles("drag", itemId, order, groupId,);
    }
    const dragLeaveFileHandler = (e, itemId, order, groupId) => {
        e.preventDefault();
        updateStatusFiles("none", itemId, order, groupId,);
    }
    const dropFileHandler = (e, item, answerId, groupId = null, orderId = 0) => {
        e.preventDefault();
        const token = localStorage.getItem("token");
        let files = null;

        if (e.dataTransfer === undefined) {
            files = Array.from(e.target.files);
        } else {
            files = [...e.dataTransfer.files];
        }

        let errorFileTypeAndSize = "";
        files.forEach(f => {
                if (!FILE_TYPES.find(t => t === f.type)) {
                    let index = files.findIndex(t => t.name !== f.name && t.size !== f.size);
                    files.splice(index, 1);
                    errorFileTypeAndSize += t("has not allow format", {fileName: f.name})
                }
                if (f.size > FILE_SIZE) {
                    let index = files.findIndex(t => t.name !== f.name && t.size !== f.size);
                    files.splice(index, 1);
                    const fileSizeInMb = FILE_SIZE / 1024 / 1024;
                    errorFileTypeAndSize += t("has size more than", {fileName: f.name, maxFileSize: `${fileSizeInMb}`})
                }
                if (f.size <= 0) {
                    let index = files.findIndex(t => t.name !== f.name && t.size !== f.size);
                    files.splice(index, 1);
                    errorFileTypeAndSize += t("is empty", {fileName: f.name})
                }
            }
        );
        if (errorFileTypeAndSize !== "") {
            dispatch(openMessage(errorFileTypeAndSize, 'warning'))
        }

        let tokens = [];
        let count = files.length;

        files.forEach(file => {
            let formData = new FormData();
            formData.append("partyId", party.id)
            formData.append("questionId", item.id)
            formData.append("orderId", orderId)
            if (groupId) {
                formData.append("groupId", groupId)
            }
            formData.append("file", file)

            updateStatusFiles("loading", item.id, orderId, groupId);

            axiosInstanceWithToken.post(`attachment`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Accept": "multipart/form-data",
                    }
                }
            )
                .then(response => {
                    if (response.data.data && response.data.data.token) {
                        tokens.push(response.data.data.token);
                        if (tokens.length === count) {
                            if (tokens.length === 0) {
                                updateStatusFiles("none", item.id, orderId, groupId)
                                return;
                            }
                            dataSendFile(tokens, item, answerId, groupId, orderId);
                        }
                    } else {
                        dispatch(openMessage(t("Some files are not uploaded"), 'warning'))
                        count -= 1;
                        if (tokens.length === count) {
                            if (tokens.length === 0) {
                                updateStatusFiles("none", item.id, orderId, groupId)
                                return;
                            }
                            dataSendFile(tokens, item, answerId, groupId, orderId);
                        }
                    }
                })
                .catch(error => {
                    console.log(error)
                    try {
                        if (error.response.status === 401 || error.response.status === 403) {
                            dispatch(checkAuthError());
                            dispatch(openMessage(t("You don't have access to upload files"), 'error'))
                        } else {
                            dispatch(openMessage(t("The request failed"), 'error'))
                        }
                    } catch {
                    }
                    updateStatusFiles("none", item.id, orderId, groupId)
                    console.log("Update status files error")
                })
        })
        e.target.value = "";
    }

    const newFile = useSelector(state => state.filesReducer.newFileData)

    useEffect(() => {
        if (newFile) {
            const newValue = [];
            newFile.attachments.forEach(attachment => {
                newValue.push({value: attachment.displayName, attachmentId: attachment.id, id: newFile.id})
            })

            if (newFile.groupId) {
                setValuesViews(prev => ({
                        ...prev,
                        [newFile.groupId]: {
                            ...prev[newFile.groupId],
                            [newFile.groupOrder]: {
                                ...prev[newFile.groupId][newFile.groupOrder],
                                [newFile.questionId]: {
                                    ...prev[newFile.groupId][newFile.groupOrder][newFile.questionId],
                                    error: false, array: [...newValue]
                                }
                            }
                        }
                    })
                );
            } else {
                setValues(prev => ({
                    ...prev,
                    [newFile.questionId]: {...prev[newFile.questionId], error: false, array: [...newValue]}
                }));
            }
        }
    }, [newFile])

    const dataDeleteView = (answerId, questionId, order, itemId) => {
        if (order) order = parseInt(order);
        if (questionId) questionId = parseInt(questionId);
        const formData = {};
        formData.partyId = party.id;
        formData.answerId = answerId === undefined ? v4() : answerId;
        formData.questionId = questionId;
        formData.text = "";
        formData.groupOrder = order;
        formData.timestamp = new Date().getTime();
        formData.groupId = itemId;

        dispatch(answerUpdate(formData));
    }

    const deleteView = (order, itemId) => {
        const newValues = {...valuesViews[itemId]}

        Object.keys(newValues[order]).forEach(key => {
            if (newValues[order][key].value !== "") {
                const quest = findItem(key, order, itemId);
                //party.items.find(a => a.id === itemId && a.views).views[0].questions.find(q => q.questionType === 6 && q.id === key);
                if (quest) {
                    if (newValues[order][key].length !== 0) {
                        dataDeleteView(newValues[order][key][0].id, key, order, itemId);
                    }
                } else if (Array.isArray(newValues[order][key])) {
                    newValues[order][key].forEach(a => {
                        if (a.value !== "") {
                            dataDeleteView(a.id, key, order, itemId);
                        }
                    })
                } else {
                    dataDeleteView(newValues[order][key].id, key, order, itemId);
                }
            }
        })
        delete newValues[order];

        if (Object.keys(newValues).length < 1) {
            newValues[order] = generateOneMoreView(itemId);
        }
        setValuesViews(prev => ({...prev, [itemId]: newValues}))
    }
    const addView = (itemId) => {
        const newValues = {...valuesViews[itemId]};

        let order = parseInt(Object.keys(newValues)[Object.keys(newValues).length - 1]) + 1;

        if (isNaN(order)) order = 0;

        if (newValues[order - 1]) {
            newValues[order] = generateOneMoreView(itemId);
        }

        setValuesViews(prev => ({...prev, [itemId]: newValues}))
    }

    const generateOneMoreView = (itemId) => {
        const view = party.presets.find(p => p.items.find(a => a.id === itemId && a.views)).items.find(a => a.id === itemId && a.views).views[0];
        let returnValue = {};
        view.questions.filter(q => q.branch === false).forEach(quest => {
            if (quest.allowMultiple) {
                returnValue = {
                    ...returnValue,
                    [quest.id]: {
                        required: quest.required,
                        array: [{value: "", id: v4(), required: quest.required, errorText: ""}]
                    }
                }
            } else if (quest.questionType === 6) {
                returnValue = {
                    ...returnValue,
                    [quest.id]: {required: quest.required, array: []}
                }
            } else {
                returnValue = {
                    ...returnValue,
                    [quest.id]: {value: "", id: v4(), required: quest.required, errorText: ""}
                }
            }
        })
        return returnValue;
    }

    const submitHandler = (e) => {
        e.preventDefault();

        const scroll = window.scrollY;
        setSubmitLoading(true);
        let valid = true;
        let newValues = JSON.parse(JSON.stringify(values));

        Object.keys(newValues).forEach(key => {
            if (newValues[key]) {
                if (newValues[key].value !== undefined) {
                    // if validate Other || newValues[key].value.toString().indexOf("o:") !== -1
                    if ((newValues[key].value === "") && newValues[key].required === true) {
                        newValues[key].errorText = "This field is required"
                        valid = false;
                    } else if (newValues[key].value === "DateNoValidSecret") {
                        newValues[key].errorText = "Invalid date format"
                        valid = false;
                    }
                } else {
                    if (newValues[key].array.length > 0) {
                        newValues[key].array.forEach(v => {
                            if (v.value === "" && v.required === true) {
                                v.errorText = "This field is required"
                                valid = false;
                            }
                        })
                    } else {
                        if (newValues[key].required) {
                            newValues[key].error = true;
                            valid = false;

                            if (!newValues[key].isFileType) {
                                newValues[key].array.push({
                                    value: "", id: v4(),
                                    required: newValues[key].required, errorText: "This field is required",
                                    order: 0
                                })
                            }
                        }
                    }
                }
            }
        })

        setValues(newValues);

        const newValuesViews = JSON.parse(JSON.stringify(valuesViews));
        Object.keys(newValuesViews).forEach(key => {
            if (newValuesViews[key]) {
                Object.keys(newValuesViews[key]).forEach(key2 => {
                    if (newValuesViews[key][key2]) {
                        Object.keys(newValuesViews[key][key2]).forEach(key3 => {
                            if (newValuesViews[key][key2][key3]) {
                                if (newValuesViews[key][key2][key3].value !== undefined) {
                                    // if validate Other newValuesViews[key][key2][key3].value.toString().indexOf("o:") === -1
                                    if ((newValuesViews[key][key2][key3].value === "") && newValuesViews[key][key2][key3].required === true) {
                                        newValuesViews[key][key2][key3].errorText = "This field is required"
                                        valid = false;
                                    } else if (newValuesViews[key][key2][key3].value === "DateNoValidSecret") {
                                        newValuesViews[key][key2][key3].errorText = "Invalid date format"
                                        valid = false;
                                    }
                                } else {

                                    if (newValuesViews[key][key2][key3].array.length > 0) {
                                        newValuesViews[key][key2][key3].array.forEach(v => {
                                            if (v.value === "" && v.required === true) {
                                                v.errorText = "This field is required"
                                                valid = false;
                                            }
                                        })
                                    } else {
                                        if (newValuesViews[key][key2][key3].required) {
                                            newValuesViews[key][key2][key3].error = true;
                                            valid = false;

                                            if (!newValuesViews[key][key2][key3].isFileType) {
                                                newValuesViews[key][key2][key3].array.push({
                                                    value: "",
                                                    id: v4(),
                                                    required: newValues[key].required,
                                                    errorText: "This field is required",
                                                    order: 0
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }
                })
            }
        })

        setValuesViews(newValuesViews)

        if (valid === true) {
            const token = localStorage.getItem("token");
            axiosInstanceWithToken.post(`party/${party.id}/submit`, null)
                .then(response => {
                    if (response.data.success) {
                        setIsSubmit(true);
                        dispatch(openMessage(t("We have received a notification and will check your answers shortly"), "success"))
                    }
                })
                .catch(error => {
                    try {
                        if (error.response.status === 401 || error.response.status === 403) {
                            dispatch(checkAuthError());
                            dispatch(openMessage(t("You don't have access to submit this party"), 'error'))
                        } else {
                            dispatch(openMessage(t("The request failed"), 'error'))
                        }
                    } catch (e) {
                        dispatch(openMessage(t("Network error"), 'error'))
                    }
                });
        } else {
            dispatch(openMessage(t("Please fill out all required fields marked in red"), 'error'))
        }


        setSubmitLoading(false);
        window.scrollTo(0, scroll);
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        for (let a = 0; a < result.length; a++) {
            result[a].index = a;
        }
        return result;
    };
    const onDragEnd = (result, item, fileId, groupId = null, order = 0) => {
        if (!result.destination) return;

        if (groupId) {
            const items = reorder(
                valuesViews[groupId][order][item.id].array,
                result.source.index,
                result.destination.index
            );
            setValuesViews(prev => ({
                    ...prev,
                    [groupId]: {
                        ...prev[groupId],
                        [order]: {
                            ...prev[groupId][order],
                            [item.id]: {
                                ...prev[groupId][order][item.id],
                                array: [...items.sort((a, b) => a.index - b.index)]
                            }
                        }
                    }
                })
            );

            setTimeout(() => {
                dataSendFile("", item, fileId, groupId, order);
            }, 500);
        } else {
            const items = reorder(
                values[item.id].array,
                result.source.index,
                result.destination.index
            );
            setValues(prev => ({
                ...prev,
                [item.id]: {...prev[item.id], array: [...items.sort((a, b) => a.index - b.index)]}
            }));
            setTimeout(() => {
                dataSendFile("", item, fileId);
            }, 500);
        }
    }

    const searchFilter = (preset) =>{
        let filteredItems = preset.items;
        let errorAccordion = false;

        const checkValue = (v) => {
            if(!v || v.presetId !== preset.id) return;
            if((v.errorText && v.errorText !== "")
                || (v.array && v.array.some(a => a.errorText && a.errorText !== ""))
                || (v.error)){
                errorAccordion = true;
            }
        }
        Object.keys(values).forEach(o => checkValue(values[o]));
        Object.keys(valuesViews).forEach(key => {
            if (!valuesViews[key]) return;
            Object.keys(valuesViews[key]).forEach(key2 =>
            {
                if (!valuesViews[key][key2]) return;
                Object.keys(valuesViews[key][key2]).forEach(key3 => {
                        if (!valuesViews[key][key2][key3]) return;
                        checkValue(valuesViews[key][key2][key3])
                    }
                );

            });
        })

        if(searchText){
            filteredItems = preset.items.filter(i => !i.views && i.name && i.name.toLowerCase().includes(searchText.toLowerCase()));
            let viewItems = JSON.parse(JSON.stringify(preset.items.filter(i => i.views)));

            for(let i =0; i < viewItems.length; i++){
                for(let a = 0; a < viewItems[i].views.length; a++){
                    viewItems[i].views[a].questions = viewItems[i].views[a].questions.filter(i => i.name && i.name.toLowerCase().includes(searchText.toLowerCase()));
                    if(viewItems[i].views[a].questions.length === 0){
                        viewItems = [];
                        break;
                    }
                }
            }
            filteredItems.push(...viewItems);
        }
        return {filteredItems, errorAccordion};
    }

    return (
        <>
            {
                party
                    ?
                    <>
                        <SearchFloatButton
                            sx={{
                                position: 'fixed',
                                bottom: 25,
                                left: `calc(100vw - ${party.presets.length > 1 ? 170 : 100}px)`,
                            }}
                        >
                            <TextField value={searchText}
                                       onChange={e => setSearchText(e.target.value)}
                                       label="Search" variant="outlined" size={"small"}
                                       sx={{margin: "10px"}}
                            />
                        </SearchFloatButton>
                        {
                            party.presets.length > 1 ?
                                <>
                                    <Fab color="primary"
                                         sx={{
                                             position: 'fixed',
                                             bottom: 25,
                                             left: "calc(100vw - 100px)",
                                         }}
                                         onClick={() => setOpenDrawer(!openDrawer)}>
                                        <FormatListBulletedIcon/>
                                    </Fab>
                                    <div style={{
                                        position: "fixed",
                                        top: "90px",
                                        left: 0,
                                        minWidth: "150px",
                                        maxWidth: "300px",
                                        width: "300px",
                                        transition: ".3s",
                                        transform: `translateX(${(openDrawer ? "0" : "-100%")})`,
                                        zIndex: 2
                                    }}>
                                        <Paper id={"navByModule"}
                                               sx={{height: "calc(100vh - 90px);", overflow: "auto"}}
                                               elevation={4}>
                                            {
                                                <List sx={{paddingTop: 0}}>
                                                    <Typography variant="h6" gutterBottom
                                                        style={{paddingLeft: "16px", fontWeight:"bold", paddingTop: "6px"}}>
                                                        Modules
                                                    </Typography>
                                                    {party.presets
                                                        .map((preset, index) => {
                                                            let {filteredItems} = searchFilter(preset);
                                                            if(filteredItems.length === 0) return null;
                                                            return <>
                                                                <ListItem key={index} disablePadding>
                                                                <Link href={`#${preset.id}-${preset.name}`} underline="none"
                                                                      sx={{width: "100%"}}>
                                                                    <ListItemButton>
                                                                        <ListItemText className={"listItemTextOneRow"} sx={{textOverflow: "ellipsis",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden"}}>
                                                                            <span style={{textOverflow: "ellipsis",
                                                                                whiteSpace: "nowrap",
                                                                                overflow: "hidden", color:"#036aaa"}}>
                                                                                {`${preset.name}`}
                                                                            </span>
                                                                        </ListItemText>
                                                                    </ListItemButton>
                                                                </Link>
                                                            </ListItem>
                                                            </>
                                                        })}
                                                </List>
                                            }
                                        </Paper>
                                    </div>
                                </>
                                : null
                        }
                    </>
                    : null
            }


            <Container sx={{paddingTop: 3, paddingLeft: 1, paddingRight: 1}}>
                <Backdrop sx={{color: '#fff', zIndex: 10}} open={reload}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Backdrop open={copyLoading} sx={{zIndex: 10}}>
                    <CircularProgress sx={{color: "white"}}/>
                </Backdrop>
                {/*<Dialog open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Do you want to continue?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You will lose all data of the current form if you continue.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={onClickContinueButton} autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>*/}

                {isGetValue === true ?

                    <Box sx={{maxWidth: 1000, margin: "auto"}}>
                        <Card sx={{width: "100%", zIndex: 1, transition: ".2s"}}>
                            <CardContent>
                                <Stack spacing={2} justifyContent={"center"}>
                                    {
                                        isGetValue === true && party ? (thisApplicant ? (<>
                                                <div style={{display:"flex", marginTop: "15px", marginBottom: "15px"}}>
                                                    <IconButton
                                                        aria-label="back"
                                                        sx={{width: 40, height: 40}}
                                                        onClick={() => navigate('/parties')}
                                                        onMouseDown={e => e.preventDefault()}
                                                    >
                                                        <ArrowBackIcon/>
                                                    </IconButton>
                                                    <Typography textAlign={"center"} variant={"h4"} sx={{width:"calc(100% - 60px)"}}>
                                                        {party ? party.name : ""} / {party ? party.templateName : ""}
                                                    </Typography>
                                                </div>
                                                    {
                                                        values || valuesViews ?

                                                            party.presets.map((preset, index) => {
                                                                let {filteredItems, errorAccordion} = searchFilter(preset);

                                                                if(filteredItems.length === 0) return null;
                                                                return <PresetComponent
                                                                    partyId={party.id}
                                                                    presetId={preset.id}
                                                                    isCollapsed={preset.isCollapsed}
                                                                    title={`${preset.name}`}
                                                                    id={`${preset.id}-${preset.name}`}
                                                                    isAccordion={party.presets.length !== 1}
                                                                    key={`module-${preset.id}`}
                                                                    error={errorAccordion}>
                                                                    <Stack spacing={2}>
                                                                        {
                                                                            filteredItems.map((item) => {
                                                                                if (item.type === 0) {
                                                                                    return <CommonComponent
                                                                                        key={`module-${preset.id}-${item.id}`}
                                                                                        item={item}
                                                                                        order={0}
                                                                                        value={values[item.id]}
                                                                                        dataSendFile={dataSendFile}
                                                                                        dragStartFileHandler={dragStartFileHandler}
                                                                                        dragLeaveFileHandler={dragLeaveFileHandler}
                                                                                        dropFileHandler={dropFileHandler}
                                                                                        handleAddNewTextField={handleAddNewTextField}
                                                                                        handleChangeCheckbox={handleChangeCheckbox}
                                                                                        handleChangeDate={handleChangeDate}
                                                                                        handleChangeRadio={handleChangeRadio}
                                                                                        handleChangeText={handleChangeText}
                                                                                        handleClickRemoveTextField={handleClickRemoveTextField}
                                                                                        handleMultipleText={handleMultipleText}
                                                                                        handleChangeCheckboxWithOther={handleChangeCheckboxWithOther}
                                                                                        handleChangeCheckboxWithOtherChangeText={handleChangeCheckboxWithOtherChangeText}
                                                                                        handleChangeRadioWithOther={handleChangeRadioWithOther}
                                                                                        handleChangeRadioWithOtherChangeText={handleChangeRadioWithOtherChangeText}
                                                                                        isLocked={isLocked}
                                                                                        onDragEnd={onDragEnd}
                                                                                        updateStatusFiles={updateStatusFiles}
                                                                                        statusFile={dragValues[item.id] ?
                                                                                            dragValues[item.id].status : "none"}
                                                                                    />
                                                                                } else {
                                                                                    return (
                                                                                        <Card key={`view-${preset.id}-${item.id}`}>
                                                                                            <CardContent
                                                                                                sx={{background: "#f8f8f8"}}
                                                                                                className={"viewCardTypeOne"}>
                                                                                                <Typography
                                                                                                    variant={"h6"} sx={{
                                                                                                    marginTop: 1,
                                                                                                    marginBottom: 2
                                                                                                }}>{item ? item.name : ""}</Typography>
                                                                                                {

                                                                                                    valuesViews[item.id] ?
                                                                                                        Object.keys(valuesViews[item.id]).map((key) => {
                                                                                                            let view = item.views.find(v => v.order === key);
                                                                                                            if (!view && !valuesViews[item.id][key]) return null;
                                                                                                            else if (!view) {
                                                                                                                view = item.views[0];
                                                                                                            }
                                                                                                            if(!view) return null;
                                                                                                            return <React.Fragment
                                                                                                                key={`${preset.id}-${key}+${item.id}`}>
                                                                                                                <div
                                                                                                                    style={{
                                                                                                                        display: "inline-flex",
                                                                                                                        width: "100%",
                                                                                                                        position: "relative"
                                                                                                                    }}>
                                                                                                                    <Card
                                                                                                                        className={"viewPaper"}>
                                                                                                                        <Stack
                                                                                                                            direction="row">
                                                                                                                            <CardContent
                                                                                                                                sx={{
                                                                                                                                    width: "100%",
                                                                                                                                    display: "flex",
                                                                                                                                    flexDirection: "column"
                                                                                                                                }}>

                                                                                                                                <Stack
                                                                                                                                    spacing={2}
                                                                                                                                    direction="column">
                                                                                                                                    {
                                                                                                                                        view.questions.map(itm => {
                                                                                                                                            return <CommonComponent
                                                                                                                                                key={`view-${preset.id}-${item.id}-${key}-${itm.id}`}
                                                                                                                                                item={itm}
                                                                                                                                                groupId={item.id}
                                                                                                                                                order={key}
                                                                                                                                                value={valuesViews[item.id][key][itm.id]}
                                                                                                                                                dataSendFile={dataSendFile}
                                                                                                                                                dragStartFileHandler={dragStartFileHandler}
                                                                                                                                                dragLeaveFileHandler={dragLeaveFileHandler}
                                                                                                                                                dropFileHandler={dropFileHandler}
                                                                                                                                                handleAddNewTextField={handleAddNewTextField}
                                                                                                                                                handleChangeCheckbox={handleChangeCheckbox}
                                                                                                                                                handleChangeDate={handleChangeDate}
                                                                                                                                                handleChangeRadio={handleChangeRadio}
                                                                                                                                                handleChangeText={handleChangeText}
                                                                                                                                                handleClickRemoveTextField={handleClickRemoveTextField}
                                                                                                                                                handleMultipleText={handleMultipleText}
                                                                                                                                                handleChangeCheckboxWithOther={handleChangeCheckboxWithOther}
                                                                                                                                                handleChangeCheckboxWithOtherChangeText={handleChangeCheckboxWithOtherChangeText}
                                                                                                                                                handleChangeRadioWithOther={handleChangeRadioWithOther}
                                                                                                                                                handleChangeRadioWithOtherChangeText={handleChangeRadioWithOtherChangeText}
                                                                                                                                                isLocked={isLocked}
                                                                                                                                                onDragEnd={onDragEnd}
                                                                                                                                                updateStatusFiles={updateStatusFiles}
                                                                                                                                                statusFile={dragValues[item.id]
                                                                                                                                                && dragValues[item.id][key]
                                                                                                                                                && dragValues[item.id][key][itm.id]
                                                                                                                                                    ? dragValues[item.id][key][itm.id].status
                                                                                                                                                    : "none"}
                                                                                                                                            />
                                                                                                                                        })
                                                                                                                                    }
                                                                                                                                </Stack>
                                                                                                                            </CardContent>
                                                                                                                        </Stack>
                                                                                                                    </Card>
                                                                                                                    <div
                                                                                                                        className={"deleteViewButton"}>
                                                                                                                        <PopupState
                                                                                                                            variant="popover"
                                                                                                                            popupId="demo-popup-popover">
                                                                                                                            {(popupState) => (
                                                                                                                                <div>
                                                                                                                                    <IconButton
                                                                                                                                        disabled={isLocked} {...bindTrigger(popupState)}
                                                                                                                                        sx={{borderRadius: "50%"}}
                                                                                                                                        variant="contained"
                                                                                                                                        color="error"><DeleteIcon/></IconButton>
                                                                                                                                    <Popover
                                                                                                                                        {...bindPopover(popupState)}
                                                                                                                                        anchorOrigin={{
                                                                                                                                            vertical: 'bottom',
                                                                                                                                            horizontal: 'center',
                                                                                                                                        }}
                                                                                                                                        transformOrigin={{
                                                                                                                                            vertical: 'top',
                                                                                                                                            horizontal: 'center',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <Typography
                                                                                                                                            sx={{p: 2}}>
                                                                                                                                            {t("Do you really want to delete this group")}</Typography>
                                                                                                                                        <div
                                                                                                                                            style={{
                                                                                                                                                display: "flex",
                                                                                                                                                justifyContent: "flex-end",
                                                                                                                                                paddingBottom: "5px",
                                                                                                                                                marginRight: "5px"
                                                                                                                                            }}>
                                                                                                                                            <Button
                                                                                                                                                variant="contained"
                                                                                                                                                onClick={() => {
                                                                                                                                                    deleteView(key, item.id);
                                                                                                                                                    popupState.close();
                                                                                                                                                }}>
                                                                                                                                                {t("Yes")}
                                                                                                                                            </Button>
                                                                                                                                            <Button
                                                                                                                                                variant="contained"
                                                                                                                                                color={"error"}
                                                                                                                                                onClick={popupState.close}
                                                                                                                                                sx={{marginLeft: "5px"}}>
                                                                                                                                                {t("No")}
                                                                                                                                            </Button>
                                                                                                                                        </div>

                                                                                                                                    </Popover>
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </PopupState>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </React.Fragment>

                                                                                                        })
                                                                                                        : null
                                                                                                }
                                                                                                <Button
                                                                                                    disabled={isLocked}
                                                                                                    sx={{
                                                                                                        width: {
                                                                                                            xs: "100%",
                                                                                                            sm: 100
                                                                                                        },
                                                                                                        marginTop: "5px"
                                                                                                    }}
                                                                                                    onClick={() => addView(item.id)}
                                                                                                    variant="contained">{t("Add")}</Button>
                                                                                            </CardContent>
                                                                                        </Card>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </Stack>
                                                                </PresetComponent>
                                                            })

                                                            : <Typography>{t("Loading")}</Typography>
                                                    }
                                                    <Typography style={{fontSize: "0.9em"}} variant={"h6"}>
                                                        {t("Party message")}
                                                    </Typography>
                                                    <LoadingButton loading={submitLoading}
                                                                   sx={{width: {xs: "100%", sm: 280}, margin: "10px 0"}}
                                                                   disabled={isSubmit || isLocked || (party && party.isSubmitted)}
                                                                   onClick={submitHandler} variant="contained"
                                                                   color="success">
                                                        {t("DATA COMPLETED FOR CLP REVIEW")}
                                                    </LoadingButton>

                                                    {!isSubmit || (party && !party.isSubmitted) ?
                                                        <Typography style={{fontSize: "0.9em"}} variant={"h6"}>
                                                            {t("You can e-mail CLP Team to re-open CFlow at any time")}
                                                        </Typography> : null}
                                                    {/*isSubmit || ((party && party.isSubmitted) && isLocked === false) ?
                                                <Typography style={{fontSize: "0.9em"}} variant={"h6"}>We have received a notification and will check your answers shortly. You can still edit your data until locked.</Typography>
                                                : isSubmit || (party && party.isSubmitted && isLocked)
                                                    ? <Typography variant={"h6"}>We have received a notification and will check your answers shortly.</Typography>
                                                    : null */}
                                                </>
                                            ) : <Typography variant={"h5"}
                                                            align={"center"}>{t("Party not found")}</Typography>)
                                            : <CircularProgress
                                                sx={{position: "absolute", left: "calc(50% - 20px)", top: "30%"}}/>
                                    }
                                </Stack>
                            </CardContent>
                        </Card>
                    </Box>
                    : party && party === -1 ?
                        <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                            <Typography variant={"h5"} textAlign={"center"}>{t("No access")}</Typography>
                            <Button sx={{margin: "auto"}} onClick={() => navigate('/parties')} variant="text">
                                {t("Go to home page")}
                            </Button>
                        </div>
                        : <CircularProgress sx={{position: "absolute", left: "calc(50% - 20px)", top: "30%"}}/>}
                <div style={{marginBottom: "20px"}}/>
            </Container>
        </>
    )
}

export default PartyPage;