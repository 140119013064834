import AuthenticatorDialog from "./AuthenticatorDialog";

const MicrosoftAuthenticatorDialog = ({open, onClose}) => {
    return (
        <AuthenticatorDialog open={open} onClose={onClose} title={"Microsoft Authenticator"}
                             playMarketLink={"https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=ru&gl=US"}
                             appStoreLink={"https://apps.apple.com/ru/app/microsoft-authenticator/id983156458"}
        />
    )
}

export default MicrosoftAuthenticatorDialog