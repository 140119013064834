import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {FormControl, FormGroup, FormHelperText, FormLabel, TextField} from "@mui/material";
import React from "react"
import StatusComponent from "./StatusComponent";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const DateComponent = React.memo(({item, value, onChange, errorText = "", onFocus, status = "none"}) => {
    return (
        <FormControl disabled={!item.editable} required={item.required}>
            <div style={{display: "flex"}}>
                <FormLabel disabled={!item.editable} error={errorText !== ""}
                           id="demo-date-buttons-group-label">{item.displayText}</FormLabel>
                <StatusComponent status={status}/>
            </div>
            <FormGroup
                aria-labelledby="demo-date-buttons-group-label"
                name="date-buttons-group"

            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={value === "" ? null : value}
                        onChange={onChange}
                        renderInput={(params) => <TextField
                            onPaste={e => {
                                let copyValue = (e.clipboardData || window.clipboardData).getData('text');
                                if (isNaN(Date.parse(copyValue))) e.preventDefault();
                            }}
                            onDrop={(e) => {
                                preventDefaultOnDropFiles(e);
                                let value = e.dataTransfer.getData("text");
                                if (isNaN(Date.parse(value))) e.preventDefault();
                            }}
                            {...params} />}
                        error={errorText !== ""}
                        onFocus={onFocus}
                        disabled={!item.editable}

                    />
                </LocalizationProvider>
            </FormGroup>
            {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
        </FormControl>
    )
}, (prev, next) =>
    prev.value === next.value &&
    prev.errorText === next.errorText &&
    prev.status === next.status &&
    prev.item.displayText === next.item.displayText)

export default DateComponent;