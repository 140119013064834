import './App.css';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    Select,
    MenuItem,
    CircularProgress,
    Snackbar,
    Alert, Popover, useMediaQuery, IconButton
} from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import logo from "./assets/logo.png"
import {useDispatch, useSelector} from 'react-redux';
import AuthPage from './pages/AuthPage';
import ApplicantsPage from './pages/ApplicantsPage';
import {logout} from './redux/reducers/authReducer';

import {
    Routes,
    Route,
    Navigate,
    useNavigate
} from "react-router-dom";
import {checkAuthUser} from './redux/asyncAction/auth';
import {useEffect, useLayoutEffect, useState} from 'react';
import {changeCurrentApplicant, setApplicantsNull} from './redux/reducers/applicantsReducer';
import PartyPage from './pages/PartyPage';
import {closeMessage, openMessage} from './redux/reducers/messageReducer';
import ResetPassword from './pages/ResetPassword';
import ActivatePage from './pages/ActivatePage';
import AuthenticatorPage from "./pages/AuthenticatorPage";
import TwoFactorAuthentication from "./pages/TwoFactorAuthentication";
import PhoneNumberPage from "./pages/PhoneNumberPage";
import PhoneTwoFactorAuthentication from "./pages/PhoneTwoFactorAuthentication";
import {useTranslation, useTranslationChange} from 'i18nano';
import {setReload} from "./redux/reducers/partyReducer";
import * as React from "react";
import {TokenApiService} from "./services/TokenApiService";
import {axiosInstance, axiosInstanceWithToken} from "./instances/axiosInstance";
import axios from "axios";
import {loadingButtonClasses} from "@mui/lab";

export const API_URL = window.location.hostname === "cflow.carolynleepllc.com"
    ? "https://api.cflow.carolynleepllc.com"
    : "https://dev.api.cflow.carolynleepllc.com";

const App = () => {
    const navigate = useNavigate();
    const t = useTranslation();

    const isAuth = useSelector(state => state.authReducer.isAuth)
    const user = useSelector(state => state.applicantsReducer.user)
    const dispatch = useDispatch();
    const currentApplicant = useSelector(state => state.applicantsReducer.currentApplicant);

    const [currentLanguage, setCurrentLanguage] = useState("en");
    const [languages, setLanguages] = useState([]);
    const translation = useTranslationChange();

    useLayoutEffect(() => {
        dispatch(checkAuthUser());
        fetch(`${process.env.PUBLIC_URL}/locales/languages.json`)
            .then((response) => {
                return response.json();
            })
            .then(data => {
                let localStorageLanguage = localStorage.getItem("lang");
                if (localStorageLanguage) {
                    if (data.findIndex(l => l.code === localStorageLanguage) === -1) {
                        setCurrentLanguage("en");
                        localStorageLanguage = "en";
                    } else {
                        setCurrentLanguage(localStorageLanguage);
                    }
                }
                setLanguages(data);
            });
    }, [])
    useEffect(() => {
        if (user) {
            if (localStorage.getItem("lastApplicant")) {
                dispatch(changeCurrentApplicant(localStorage.getItem("lastApplicant")));
            } else {
                const number = localStorage.getItem("lastApplicant") ? localStorage.getItem("lastApplicant") : user.applicants.length > 0 ? user.applicants[0].id : -2;

                //navigate("/parties/"+number)
                dispatch(changeCurrentApplicant(number));
                localStorage.setItem("lastApplicant", number)
            }
        }
    }, [user])

    const handleChangeApplicants = e => {
        dispatch(changeCurrentApplicant(e.target.value));
        setAnchorEl(null);
        navigate("/parties")
        localStorage.setItem("lastApplicant", e.target.value)
    }

    const changeLanguage = e => {
        setCurrentLanguage(e.target.value);
        localStorage.setItem("lang", e.target.value);
        setAnchorEl(null);
        translation.change(e.target.value);
        dispatch(setReload(true));
    }

    const SelectLanguage = ({style}) =>{
        return (<Select
            value={currentLanguage}
            onChange={changeLanguage}
            sx={style}
        >
            {languages.map(item => <MenuItem
                key={`${item.code}-${item.lang}`}
                value={item.code}>
                <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${item.countryCode.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${item.countryCode.toLowerCase()}.png 2x`}
                    alt=""
                    style={{marginRight: 10}}
                />
                {item.lang}
            </MenuItem>)}
        </Select>)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const stateMessage = useSelector(state => state.messageReducer);

    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const appBarButtons = isAuth === true ?
        <>
            {
                user && user.applicants.length > 1 ? <Select
                    labelId="demo-simple-select-label"
                    id="applicants"
                    value={currentApplicant}
                    label="Applicants"
                    onChange={handleChangeApplicants}

                    sx={{color: matches ? "white" : "black", border: "white", maxWidth: 250, minWidth: 100}}
                >
                    {user.applicants.map(item => {
                        return <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    })}
                </Select> : null
            }
            <SelectLanguage style={{color: matches ? "white" : "black", borderColor: "white", maxWidth: 250, minWidth: 140, margin: matches ? "0 10px" : "10px 0"}} />
            <Button onClick={async () => {
                TokenApiService.Revoke(localStorage.getItem("token")).then();
                setAnchorEl(null);
                dispatch(logout());
                dispatch(setApplicantsNull());
                navigate("/login")
            }} color="inherit">
                {t("Log out")}
            </Button>
        </>
        : <SelectLanguage style={{color: matches ? "white" : "black", borderColor: "white", maxWidth: 250, minWidth: 140}} />

    let appBar = null;
    if(isAuth === false){
        appBar = <SelectLanguage style={{color: "white", borderColor: "white", maxWidth: 250, minWidth: 140}} />
    }
    else{
        if(!matches){
            appBar = <>
                <IconButton aria-describedby={id} onClick={handleClick} size="large">
                    <MoreVertIcon style={{fill:"white"}} />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div style={{padding: 10, display: "flex", flexDirection: "column"}}>
                        {appBarButtons}
                    </div>
                </Popover>
            </>
        }
        else{
            appBar = appBarButtons;
        }

    }
    return (
        <>
            <Snackbar open={stateMessage.open} autoHideDuration={6000} onClose={() => dispatch(closeMessage())}
                anchorOrigin={{vertical: "top", horizontal:"center"}}>
                <Alert onClose={() => dispatch(closeMessage())} severity={stateMessage.severity} sx={{width: '100%'}}>
                    {stateMessage.message}
                </Alert>
            </Snackbar>
            <Box sx={{flexGrow: 1}}>
                <AppBar position="static">
                    <Toolbar sx={{height: 80}}>
                        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                            <img alt={logo} src={logo} style={{height: "40px"}} onClick={() => navigate("/")}/>
                        </Typography>
                        {appBar}
                    </Toolbar>
                </AppBar>
            </Box>
            {isAuth !== -1
                ? <Routes>
                    {isAuth === false
                        ? <>
                            <Route path="/login" element={<AuthPage/>}/>
                            <Route path="/reset" element={<ResetPassword/>}/>
                            <Route path='/activate' element={<ActivatePage title={t("Activate account")}/>}/>
                            <Route path='/restore' element={<ActivatePage title={t("Reset password")}/>}/>
                            <Route path='/authenticator' element={<AuthenticatorPage/>}/>
                            <Route path='/two-factor-authentication/phone' element={<PhoneTwoFactorAuthentication/>}/>
                            <Route path='/two-factor-authentication' element={<TwoFactorAuthentication/>}/>
                            <Route path='/phoneAuthenticator' element={<PhoneNumberPage/>}/>
                            <Route
                                path="*"
                                element={<Navigate to="/login"/>}
                            />
                        </>
                        : <>
                            <Route path="/parties" element={<ApplicantsPage/>}/>
                            <Route path="/parties/:id" element={<ApplicantsPage/>}/>
                            <Route path="/party/:id" element={<PartyPage/>}/>
                            <Route
                                path="*"
                                element={<Navigate to="/parties"/>}
                            />
                        </>}
                </Routes>
                : <CircularProgress sx={{position: "absolute", left: "calc(50% - 20px)", top: "30%"}}/>
            }
        </>
    );
}

export default App;
