import {Button, Container, Stack, Typography} from "@mui/material";
import {useTranslation} from "i18nano";
import useFormattingTranslations from "../hooks/useFormattingTranslations";

const AuthenticatorAddedComponent = ({onClick}) => {
    const t = useTranslation();
    const tf = useFormattingTranslations();
    return (
        <Container>
            <Stack
                spacing={2}
                justifyContent={'center'}
            >

                <Typography variant="h4" align={'center'}>
                    {t("Authenticator added")}
                </Typography>
                <Typography variant="body1" gutterBottom align={"center"}>
                    {tf("You have successfully enable two-factor auth", null, null)}
                </Typography>

                <Button variant="contained" onClick={onClick}>{t("Ok")}</Button>
            </Stack>
        </Container>
    )
}
export default AuthenticatorAddedComponent;