import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel, InputAdornment,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import React from "react";
import StatusComponent from "./StatusComponent";
import {useTranslation} from "i18nano";
import {preventDefaultOnDropFiles} from "../helpers/stubFunctions";

const RadiosComponent = React.memo(({
                                        item, value, onChange, onChangeText, errorText = "", onFocus, status = "none",
                                        order = null, groupId = null, valueId
                                    }) => {
    const t = useTranslation();
    return (
        <FormControl disabled={!item.editable} required={item.required}
                     error={errorText !== ""}>
            <div style={{display: "flex"}}>
                <FormLabel>{item.displayText}</FormLabel>
                <StatusComponent status={status}/>
            </div>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name={`${item.id}`}
                value={value}
                onChange={onChange}
                className={"widthMaxContent"}
            >
                {
                    item.choices.map(choice => {
                        return (
                            <FormControlLabel onFocus={onFocus} key={choice.id}
                                              name={`${choice.id}`}
                                              value={choice.id} control={<Radio/>}
                                              className={"textWrap"}
                                              onKeyPress={e => {
                                                  if (value !== choice.id) {
                                                      if (e.key === "Enter") {
                                                          const ev = {
                                                              target: {value: choice.id.toString()}
                                                          }
                                                          onChange(ev, item, valueId, order, groupId);
                                                      }
                                                  }
                                              }}
                                              label={choice.text === null || choice.text === "" ? "(empty)" : choice.text}/>
                        )
                    })
                }
                {item.other === true ?
                    <FormControlLabel onFocus={onFocus}
                                      value={"Other"}
                                      checked={value === "Other" || value.indexOf("o:") !== -1}
                                      control={<Radio/>} label={t("Other")}
                                      onKeyPress={e => {
                                          if (value.indexOf("o:") === -1) {
                                              if (e.key === "Enter") {
                                                  const ev = {
                                                      target: {value: "Other"}
                                                  }
                                                  onChange(ev, item, valueId, order, groupId);
                                              }
                                          }
                                      }}

                    />
                    : null}
            </RadioGroup>
            {
                value === "Other" || value.indexOf("o:") !== -1
                    ?
                    <TextField error={errorText !== ""} value={value.substring(2)} onChange={onChangeText} label="Other"
                               variant="outlined"
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">
                                       <StatusComponent status={status}/>
                                   </InputAdornment>,
                               }}
                               onDrop={preventDefaultOnDropFiles}
                    />
                    : null
            }
            {errorText !== "" ? <FormHelperText error>{errorText}</FormHelperText> : null}
        </FormControl>
    )
}, (prev, next) =>
    prev.value === next.value &&
    prev.errorText === next.errorText &&
    prev.status === next.status &&
    prev.item.displayText === next.item.displayText)

export default RadiosComponent;