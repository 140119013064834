import {Button, Container, Paper, Typography} from "@mui/material";

import {useEffect, useState} from "react";
import axios from "axios";
import {API_URL} from "../App";
import {authSuccess} from "../redux/reducers/authReducer";
import {openMessage} from "../redux/reducers/messageReducer";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import PhoneNumberCommonComponent from "../components/PhoneNumberCommonComponent";
import EnterCodeAuthenticatorComponent from "../components/EnterCodeAuthenticatorComponent";
import * as React from "react";
import Countdown from "react-countdown";
import {useTranslation} from "i18nano";

const PhoneTwoFactorAuthentication = () => {
    const errorData = useSelector(state => state.authReducer.errorData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const t = useTranslation();

    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState();

    const [step, setStep] = useState(0);

    const [errors, setErrors] = useState([]);

    const sendCode = (code) => {
        setLoading(true)
        axios.post(`${API_URL}/user/login`,
            {
                login: errorData.email,
                password: errorData.password,
                code: code,
                twoFactorProvider: 1
            }
        ).then(response => {
            if (response.data.success) {
                dispatch(authSuccess(response.data.data));
            } else {
                dispatch(openMessage(response.data.errors[0], "error"))
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false);
            dispatch(openMessage(t("Something went wrong"), "error"));
        });
    }
    const sendSms = (phoneNumber) => {
        setLoading(true)
        setPhone(phoneNumber);
        axios.post(`${API_URL}/user/two-factor/sms`,
            {
                login: errorData.email,
                password: errorData.password,
                phone: phoneNumber
            }
        ).then(response => {
            if (response.data.success) {
                setStep(1)
                localStorage.setItem("lastSendSms", new Date().getTime().toString())
            } else {
                setErrors( [response.data.errors[0]])
                //dispatch(openMessage(response.data.errors[0], "error"))
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false);
            setErrors(["Something went wrong"])
            //dispatch(openMessage(t("Something went wrong"), "error"));
        });
    }

    /*useEffect(() => {
        if (!errorData || !errorData.email || !errorData.password) {
            navigate("/login")
        }
    }, [])*/
    const style = {padding: "40px 20px", margin: "auto", marginTop: "40px", maxWidth: "450px"};

    return (
        <Paper elevation={1} style={style}>
            <Container>
                {step === 0 ?
                    <>
                        <PhoneNumberCommonComponent errors={errors} onClick={p => sendSms(p)} loading={loading}/>
                        {errorData && errorData.data && errorData.data.availableTwoFactorProviders.includes(0) ?
                            <NavLink to={"/two-factor-authentication"}
                                     style={{display: "block", width: "100%", textAlign: "center", marginTop: "10px"}}>
                                {t("Use authenticator instead")}
                            </NavLink>
                            : null
                        }
                    </>
                    :
                    <>
                        <EnterCodeAuthenticatorComponent
                            onClick={sendCode} loading={loading}
                            title={t("Enter Code")}
                            description={<span>{t("We have sent a verification code to your phone number")}</span>}
                        />
                        <div style={{display: 'flex', justifyContent: "center"}}>
                            <Countdown
                                date={Date.now() + 60 * 1000}
                                renderer={({hours, minutes, seconds, completed}) => {
                                    if (completed) {
                                        return <Button style={{marginTop: 10}} variant="text" size={"small"}
                                                       onClick={() => sendSms(phone)}>{t("Send code again")}</Button>
                                    } else {
                                        return <Typography style={{marginTop: 10}} variant="body2" display="block"
                                                           gutterBottom>
                                            {t("You can send code again after seconds", {seconds: `${seconds === 0 ? "" : seconds}`})}

                                        </Typography>
                                    }
                                }}
                            />
                        </div>
                    </>
                }
            </Container>
        </Paper>
    )
}

export default PhoneTwoFactorAuthentication;