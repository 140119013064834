const defaultState = {
    newFileData: null
}

const SET_NEW_FILE = "SET_NEW_FILE";

export const filesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_NEW_FILE: {

            return {...state, newFileData: action.payload}
        }


        default:
            return state;
    }
}

export const setNewFile = (payload) => ({type: SET_NEW_FILE, payload: payload})