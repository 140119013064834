import {Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QRCode from "react-qr-code";
import {useTranslation} from "i18nano";

const AuthenticatorDialog = ({open, onClose, playMarketLink, appStoreLink, title}) => {
    const t = useTranslation();
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle onClose={onClose}>
                {title}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            width: 40,
                            height: 40,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                ) : null}
            </DialogTitle>
            <DialogContent>
                <Stack direction={"row"} spacing={10}>
                    <Stack spacing={1} style={{width: 250}} alignItems={"center"}>
                        <div style={{
                            height: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            overflow: "hidden"
                        }}>
                            <img style={{height: 70}}
                                 src={"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d7/Android_robot.svg/1745px-Android_robot.svg.png"}/>
                        </div>
                        <div style={{
                            height: 220,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            overflow: "hidden"
                        }}>
                            <QRCode style={{width: "100%"}} value={playMarketLink}/>
                        </div>
                        <Typography variant="h6" align={'center'}>
                            Google Play
                        </Typography>
                        <Button variant="outlined" fullWidth target="blank"
                                href={playMarketLink}>
                            {t("Download app")}
                        </Button>
                    </Stack>
                    <Stack spacing={1} style={{width: 250}} alignItems={"center"}>
                        <div style={{
                            height: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            overflow: "hidden"
                        }}>
                            <img style={{height: 60}}
                                 src={"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/Apple_logo_black.svg/800px-Apple_logo_black.svg.png"}/>
                        </div>
                        <div style={{
                            height: 220,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            overflow: "hidden"
                        }}>
                            <QRCode style={{width: "100%"}} value={appStoreLink}/>
                        </div>
                        <Typography variant="h6" align={'center'}>
                            App Store
                        </Typography>
                        <Button variant="outlined" fullWidth target="blank"
                                href={appStoreLink}>
                            {t("Download app")}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

export default AuthenticatorDialog