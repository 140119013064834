const defaultState = {
    currentParty: null,
    copyLoading: false,
    reload: false,
    lastTimestamp: 0,
}

const GET_CURRENT_PARTY = "GET_CURRENT_PARTY";
const SET_PARTY_NULL = "SET_PATRY_NULL";
const SET_COPY_LOADING = "SET_COPY_LOADING";
const SET_RELOAD = "SET_RELOAD";
const ADD_NEW_VIEW = 'ADD_NEW_VIEW'
const SET_LAST_TIMESTAMP = "SET_LAST_TIMESTAMP";
const GET_CURRENT_PARTY_ERROR = "GET_CURRENT_PARTY_ERROR"

export const partyReducer = (state = defaultState, action) => {
    switch (action.type) {
        case GET_CURRENT_PARTY: {

            return {...state, currentParty: action.payload, copyLoading: false, reload: false}
        }
        case GET_CURRENT_PARTY_ERROR: {
            return {...state, currentParty: -1}
        }
        case SET_PARTY_NULL: {
            return {...state, currentParty: null}
        }
        case SET_COPY_LOADING: {
            return {...state, copyLoading: action.payload}
        }
        case SET_RELOAD: {
            return {...state, reload: action.payload}
        }
        case ADD_NEW_VIEW: {
            const newState = {...state.currentParty};

            const views = newState.items.filter(i => i.type === 1)[0].views;

            const newView = {...views[0]};

            for (let i = 0; i < newView.questions.length; i++) {
                newView.questions.answers = [];
            }
            newView.order = views[views.length - 1].order + 1;

            newState.items.filter(i => i.type === 1)[0].views.push(newView);

            console.log(newState)
            return {...state, reload: true, currentParty: newState}
        }
        case SET_LAST_TIMESTAMP: {
            return {...state, lastTimestamp: action.payload}
        }


        default:
            return state;
    }
}

export const getCurrentPartySuccess = (payload) => ({type: GET_CURRENT_PARTY, payload})
export const getCurrentPartyError = (payload) => ({type: GET_CURRENT_PARTY_ERROR})
export const setPartyNull = () => ({type: SET_PARTY_NULL})
export const setCopyLoading = (payload) => ({type: SET_COPY_LOADING, payload: payload})
export const setReload = (reload) => ({type: SET_RELOAD, payload: reload})
export const addNewView = () => ({type: ADD_NEW_VIEW});
export const setLastTimestamp = (payload) => ({type: SET_LAST_TIMESTAMP, payload})