import {Button} from "@mui/material";
import * as React from 'react';
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import PhoneNumberCommonComponent from "./PhoneNumberCommonComponent";
import {useTranslation} from "i18nano";

const PhoneNumberAuthenticatorComponent = ({onClick, loading, onClickSkip, errors}) => {
    const t = useTranslation();
    const authenticatorIsConnected = useSelector(state => state.authReducer.authenticatorIsConnected)
    return (
        <>
            <PhoneNumberCommonComponent onClick={onClick} loading={loading} errors={errors}/>
            <Button style={{marginTop: 10}} variant="outlined" size={"large"} onClick={onClickSkip}
                    fullWidth>{t("Skip")}</Button>
            {/*<NavLink to={"/authenticator"}
                     style={{display: "block", width: "100%", textAlign: "center", marginTop: "10px"}}>
                {t("Use authenticator instead")}
            </NavLink>*/}
        </>
    )

}
export default PhoneNumberAuthenticatorComponent