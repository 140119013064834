import {Alert, InputAdornment, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {countries} from "../countries";
import {FixedSizeList} from "react-window";
import * as React from 'react';
import InputMask from "react-input-mask"
import axios from "axios";
import {API_URL} from "../App";
import {useTranslation} from "i18nano";

const PhoneNumberCommonComponent = ({onClick, loading, errors=[]}) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setCountry] = useState("US")
    const [open, setOpen] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [lastDateSendSms, setLastDateSendSms] = useState(0);
    const t = useTranslation();
    const changeCountry = (code) => {
        setTimeout(() => setOpen(false), 500)
        setCountry(code);
    }

    function renderRow(props) {
        const {index, style} = props;
        const country = countries[index];

        return (<MenuItem style={style}
                          onClick={() => changeCountry(country.code)}
                          key={country.code}
                          value={country.code}>
                <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${country.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${country.code.toLowerCase()}.png 2x`}
                    alt=""
                    style={{marginRight: 10}}
                />
                {country.label} (+{country.phone})
            </MenuItem>
        );
    }


    useEffect(() => {
        setSelectLoading(true);
        let lastTime = localStorage.getItem("lastSendSms");
        if (lastTime) {
            setLastDateSendSms(+lastTime + 60 * 1000);
        }
        axios.get(`${API_URL}/user/country`)
            .then(response => {
                setCountry(response.data.data.countryCode);
                setSelectLoading(false);
            })
            .catch(() => {
                setSelectLoading(false);
            })
    }, [])

    return (
        <Stack
            spacing={2}
            justifyContent={'center'}
        >
            <Typography variant="h4" align={'center'}>
                {t("Enter phone number")}
            </Typography>
            <Typography variant="body1" gutterBottom align={"center"}>
                {t("Please confirm your country code and enter your phone number")}
            </Typography>
            <Select
                defaultValue={'US'}
                value={country}
                open={open}
                disabled={selectLoading}
                onClick={() => setOpen(prev => !prev)}
            >
                <MenuItem sx={{display: "none"}} value={countries[countries.findIndex(a => a.code === country)].code}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${countries[countries.findIndex(a => a.code === country)].code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${countries[countries.findIndex(a => a.code === country)].code.toLowerCase()}.png 2x`}
                        alt=""
                        style={{marginRight: 10}}
                    />
                    {countries[countries.findIndex(a => a.code === country)].label}
                </MenuItem>
                <FixedSizeList
                    height={300}
                    width={400}
                    itemSize={46}
                    itemCount={countries.length}
                    overscanCount={15}
                >
                    {renderRow}
                </FixedSizeList>
            </Select>
            <InputMask mask={`## ### ## ###`}
                       value={phoneNumber}
                       onChange={e => setPhoneNumber(e.target.value)}
                       maskChar={""}
                       formatChars={{
                           '#': '[0-9]'
                       }}
                       InputProps={{
                           startAdornment: <InputAdornment position="start">
                               {"+" + countries[countries.findIndex(a => a.code === country)].phone}
                           </InputAdornment>,
                       }}
                       onKeyPress={e => {
                           if (e.code === "Enter") {
                               if (phoneNumber !== "" ||
                                   phoneNumber.replaceAll(" ", "").length < 6 ||
                                   (lastDateSendSms > new Date().getTime())
                               ) {
                                   onClick(("+" + countries[countries.findIndex(a => a.code === country)].phone + phoneNumber).replaceAll(" ", ""))
                               }
                           }
                       }}
            >
                {(inputProps) => <TextField {...inputProps} type="tel"/>}
            </InputMask>

            {errors.map(e => <Alert severity="error">{e}</Alert>)}

            <LoadingButton variant="contained"
                           disabled={phoneNumber === "" ||
                               phoneNumber.replaceAll(" ", "").length < 6 ||
                               (lastDateSendSms > new Date().getTime())}
                           onClick={() => onClick(("+" + countries[countries.findIndex(a => a.code === country)].phone + phoneNumber).replaceAll(" ", ""))}
                           loading={loading} size={"large"} loadingPosition="start" startIcon={<></>}
            >
                {lastDateSendSms > new Date().getTime() ?
                    t("You can send code again after seconds", {seconds: `${new Date(lastDateSendSms - new Date().getTime()).getSeconds()}`})
                    :
                    t("Next")}
            </LoadingButton>
        </Stack>
    )

}

export default PhoneNumberCommonComponent